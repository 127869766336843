export const calculateTotalHamd = (
    answers: Record<string,any>
): number => {

    let totalHamdSum = 0;

    for (const key in answers) {
        if (key.startsWith("hamd") && key !== 'hamd17_total') {
            const value = Number(answers[key].value);
            if (!Number.isNaN(value)) {
                totalHamdSum += value;
            }
        }
    }

    return totalHamdSum;
};

export const calculateTotalHama = (
    answers: Record<string,any>
): number => {
    let totalHamaSum = 0;

        for (const key in answers) {
            if (key.startsWith("hama") && key !== 'hama14_total') {
                const value = Number(answers[key]);
                if (!Number.isNaN(value)) {
                    totalHamaSum += value;
                }
            }
        }

    return totalHamaSum;
};