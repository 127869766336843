import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface TokenRoute {
  component: any;
  path: string;
  isLoggedIn: boolean;
  queryParams: URLSearchParams;
}

const TokenRoute = ({ component: Component, ...rest }: TokenRoute) => {
  const { state }: { state: any } = useLocation();
  const ext_ref_id = state?.ext_ref_id ?? '';
  const partnerId = state?.partnerId ?? '';
  const { queryParams, isLoggedIn, path } = rest;

  const TOKEN: string | null = queryParams.has('token') ? queryParams.get('token') : null;

  return isLoggedIn && !TOKEN ? <Component ext_ref_id={ext_ref_id} partnerId={partnerId} queryParams={state?.queryParams ?? ''} /> : TOKEN ?
    <Navigate to={{ pathname: '/validation' }} state={{ TOKEN: TOKEN, from: path, queryParams: queryParams.toString() }} />
    : <Navigate to={{ pathname: '/error' }} state={{ status: 'missingtoken', ext_ref_id: ext_ref_id ?? null, message: 'Please, provide a valid Token.' }} />

};

export default TokenRoute;