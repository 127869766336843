export const GA_PREFIX = 'ehopw_';

export const enum GA_EVENT_TYPES {
    SCREEN_VIEW = 'screen_view',
    BUTTON_CLICK = 'button_click',
    LINK_CLICK = 'link_click',
    RECORDING_PROGRESS = 'recording_progress',
};

export const enum GA_SCREEN_NAMES {
    LOADING = 'loading',
    ERROR = 'error',
    ASSESSMENT_PAGE = 'assessment',
    PAYMENT_PAGE = 'payment_info',
};

export const enum GA_SESSION_EVENT_NAMES {
    STARTED = 'started',
    COMPLETED = 'completed',
};
