// import fetch from 'cross-fetch';
import firebase, { initFirebase } from '../config/firebase';
import notifier from '../utils/notifier';
import { SurveyAction, ActionCategory } from '../containers/Assessment/constants';

initFirebase();
const firestore = firebase.firestore();
const Timestamp = firebase.firestore.Timestamp;
const dcSessionsRef = firestore.collection('dcSessions');

export const getPatientJson = async (patientId: string, extraParams: Record<string, any>) => {
    // Check if a data collection session exists
    const dataCollectionDoc = await getDataCollectionDocByRefId(patientId);
    if (!dataCollectionDoc) {
        notifier.error(`The patient with ID ${patientId} has never scheduled a meeting. An assessment cannot be completed.`);
        return null
    }

    /**
     * If the session is marked as Draft or In Progress, return back the document
     * Keep a list of times the user reopened the assessment to complete it
     * List of possible statuses:
     * id = 0 -> Completed
     * id = 1 -> Patient no show
     * id = 2 -> Save as Draft / Incomplete
     * id = 3 -> Other
     * id = 4 -> In Progress
     */
    if (dataCollectionDoc.sessionStatus?.id === 2) {
        notifier.info(`This survey for the patient with ID ${patientId} was saved as draft. Please, complete and submit.`);
        const surveyActions = dataCollectionDoc.surveyActions ?? [];
        const date = new Date();
        const action: SurveyAction = {
            id: 'open_assessment_draft',
            timestamp: firebase.firestore.Timestamp.fromMillis(date.getTime()),
            category: ActionCategory.status
        }
        surveyActions.push(action);

        return saveAssessment(dataCollectionDoc, { surveyActions: surveyActions });
    }

    if (dataCollectionDoc.sessionStatus?.id === 4) {
        notifier.info(`This survey for the patient with ID ${patientId} is in progress. Please, complete and submit.`);
        const surveyActions = dataCollectionDoc.surveyActions ?? [];
        const date = new Date();
        const action: SurveyAction = {
            id: 'open_assessment_in_progress',
            timestamp: firebase.firestore.Timestamp.fromMillis(date.getTime()),
            category: ActionCategory.status
        }
        surveyActions.push(action);

        return saveAssessment(dataCollectionDoc, { surveyActions: surveyActions });
    }

    // If the session doc already contains a status, just return a message.
    if (dataCollectionDoc.sessionStatus?.id) {
        const status = dataCollectionDoc.sessionStatus?.prompt ?? 'n/a'
        notifier.error(`The patient with ID ${patientId} has already completed the assessment with a clinician with status ${status}.`);
        return null
    }

    /**
     * If the dataCollectionDoc has no surveyAnswers, it means the user has never gone through the assessment widget with a clinician.
     * In this case, we can save meetingId, meetingStartTimestamp and all query params
     */
    if (!dataCollectionDoc.surveyAnswers) {
        const surveyActions = dataCollectionDoc.surveyActions ?? [];
        const date = new Date();
        const action: SurveyAction = {
            id: 'open_assessment',
            timestamp: firebase.firestore.Timestamp.fromMillis(date.getTime()),
            category: ActionCategory.status
        }
        surveyActions.push(action);

        return saveAssessment(dataCollectionDoc, { ...extraParams, surveyActions: surveyActions });
    }

    notifier.error(`The patient with ID ${patientId} has already completed the assessment with a clinician who has submitted the answers.`);
    return null
};

export const prepareAssessmentToSave = async (patientData: Record<string, any>, extraParams: Record<string, any>, actionsToAppend: SurveyAction[]) => {
    // Check if a data collection session exists
    const dataCollectionDoc = await getDataCollectionDoc(patientData.uid);
    if (!dataCollectionDoc) {
        notifier.error(`Impossible to save. The assessment cannot be completed. Please, contact support.`);
        return null
    }

    // Append new actions
    const oldSurveyActions = dataCollectionDoc.surveyActions ?? [];
    const newSurveyActions = [...oldSurveyActions, ...actionsToAppend];

    return saveAssessment(dataCollectionDoc, { ...extraParams, surveyActions: newSurveyActions });
};

const saveAssessment = async (patientData: Record<string, any>, newData: Record<string, any>) => {

    try {
        await dcSessionsRef.doc(patientData.uid).set({ ...newData, updatedTimestamp: Timestamp.now() }, { merge: true })
        return { ...patientData, ...newData }
    } catch (e) {
        console.log(e);
        notifier.error('Error while saving meeting information. Please, contact the Ellipsis health support.')
        return null
    }
};

const getDataCollectionDocByRefId = async (patientId: string) => {
    const docs = await dcSessionsRef.where('meetingDetails.ehRefId', '==', patientId).get().then(snapshot => snapshot.docs.map(x => x.data()));

    if (!docs.length) {
        return null
    }

    return docs[0];
};

const getDataCollectionDoc = async (docId: string) => {
    const docRef = dcSessionsRef.doc(docId);
    const doc = await docRef.get();

    if (!doc.exists) {
        return null;
    }

    return doc.data();
};