import React, { useState, useEffect, FC } from 'react';
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
import { GA_SCREEN_NAMES } from '../../constants';
import firebase from '../../config/firebase';
import { logButtonClick, logScreenView, logEvent } from '../../config/analytics';
import { Paper, FormControl, Typography, InputLabel, Select, CircularProgress, Collapse } from '@mui/material';
import { MdCheckCircle, MdError } from 'react-icons/md';
import { createStyles, makeStyles } from '@mui/styles';

import { getPatientJson, prepareAssessmentToSave } from '../../services/assessment';
import { ActionCategory } from '../../containers/Assessment/constants';

import { calculateTotalHamd, calculateTotalHama } from '../../utils/customSurvey';

import { final_survey, SurveyAction } from './constants';

import Survey from './Survey';

import logo from '../../assets/logo.png';

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            overflowX: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: 20,
        },
        paperTopRow: {
            width: '100%',
            alignItems: 'center',
            display: 'flex',
            padding: 10,
        },
        paperTopColumn: {
            backgroundColor: '#d7c5fa',
            width: '100%', display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', overflowX: 'hidden',
            position: 'fixed',
            top: '0px',
            zIndex: 10,
        },
        paperTopBox: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            padding: 10
        },
        '& .MuiPaper-root': {
            backgroundColor: '#d7c5fa',
        },
        title: {
            // color: theme.palette.primary.main,
            // fontFamily: `${theme.typography.fontFamily}`,
            fontSize: 30,
            fontWeight: 600,
        },
        search: {
            margin: 20,
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 400
        },
        input: {
            marginLeft: 8,
            flex: 1,
        },
        iconButton: {
            padding: 10,
            color: '#394f68',
        },
        divider: {
            width: 1,
            height: 28,
            margin: 4,
        },
        paperResult: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 10,
        },
        titleResult: {
            // color: theme.palette.primary.main,
            // fontFamily: `${theme.typography.fontFamily}`,
            fontSize: 15,
            fontWeight: 600,
        },
    }),
);

const getQuestions = (remoteSurvey: Record<string, any> | null, patientSurvey: Record<string, any> | null) => {
    if (!remoteSurvey) return null

    if (patientSurvey) return patientSurvey

    return remoteSurvey
}

const getAnswers = (patientAnswers: Record<string, any> | null) => {
    if (!patientAnswers) return {}
    return patientAnswers
}

interface assessmentQueryParams {
    ehRefId: string | undefined;
    ehLang: string | undefined;
    meetingId: string | undefined;
    meetingStartTimestamp: string | Date | undefined;
    clinicianFirstName: string | undefined;
    clinicianLastName: string | undefined;
}

const Assessment: FC<{
    ext_ref_id: string;
    queryParams: string;
}> = (props) => {
    const { ext_ref_id } = props;
    const classes = useStyles();
    const navigate = useNavigate();

    const [search, setSearch] = useState<string>('');
    const [patientData, setPatientData] = useState<Record<string, any>>({});
    const [loadingSpinner, setLSpinner] = useState<boolean>(false);
    const [saveSpinner, setSSpinner] = useState<boolean>(false);

    // call saveAssessment: Function;
    const save = async (data: Record<string, any>, extra: Record<string, any>, actions: SurveyAction[], event?: string, event_params?: Record<string, any>): Promise<boolean> => {
        setSSpinner(true);
        const result = await prepareAssessmentToSave(data, extra, actions);

        if (!result) {
            setSSpinner(false);
            return false
        }

        // GA event
        event && logButtonClick(GA_SCREEN_NAMES.ASSESSMENT_PAGE, event, event, event_params).logAsFunnelEvent();

        setPatientData(result);
        setSSpinner(false);

        return true
    };

    useEffect(() => {
        const { ext_ref_id, queryParams } = props;
        logScreenView(GA_SCREEN_NAMES.ASSESSMENT_PAGE, undefined, { source: 'eh_op_widget' }).logAsFunnelEvent()

        const searchQueryParams = new URLSearchParams(queryParams);

        // Prepare the assessmentQueryParams to be saved
        const assessmentQueryParams: assessmentQueryParams = {
            ehRefId: searchQueryParams.has('ehRefId') ? searchQueryParams.get('ehRefId') ?? undefined : undefined,
            ehLang: searchQueryParams.has('ehLang') ? searchQueryParams.get('ehLang') ?? 'en-US' : 'en-US',
            meetingId: searchQueryParams.has('meetingId') ? searchQueryParams.get('meetingId') ?? undefined : undefined,
            meetingStartTimestamp: searchQueryParams.has('meetingTimestamp') ? searchQueryParams.get('meetingTimestamp') ?? undefined : undefined,
            clinicianFirstName: searchQueryParams.has('clinicianFirstName') ? searchQueryParams.get('clinicianFirstName') ?? undefined : undefined,
            clinicianLastName: searchQueryParams.has('clinicianLastName') ? searchQueryParams.get('clinicianLastName') ?? undefined : undefined
        }
        const timestamp = assessmentQueryParams.meetingStartTimestamp;
        if (timestamp && typeof timestamp === 'string') {
            const epochDate = new Date(parseInt(timestamp));
            assessmentQueryParams.meetingStartTimestamp = epochDate;
        }

        // declare the async data fetching function
        const fetchData = async () => {
            // GA event
            logEvent('assmt_search', { search: ext_ref_id, source: 'eh_op_widget' });

            const patientData = await getPatientJson(ext_ref_id, { assessmentQueryParams });

            if (!patientData) {
                setPatientData({});
                setLSpinner(false);
                return
            }

            // set state with the result
            setPatientData(patientData);
            setLSpinner(false);
        }

        setSearch(ext_ref_id ?? '');
        if (ext_ref_id) {
            // call the function only if ext_ref_id (patientId) is available
            setLSpinner(true);
            fetchData();
        }

    }, [])

    const handleSessionStatus = (e: any) => {
        const value = e.target.value;
        if (!value || value === 'Select') {
            return
        }

        if (value === '3') {
            swal({
                title: "Are you sure?",
                text: "You will not be allowed to change your responses later. Do you want to proceed?",
                icon: "warning",
                buttons: ["Cancel", "Confirm"],
                content: {
                    element: "input",
                    attributes: {
                        placeholder: "Please, insert the other reason:",
                    },
                },
                dangerMode: true,
            }).then(async (answer: any) => {
                if (answer) {
                    const sessionStatus = {
                        id: "3",
                        prompt: `Other: ${answer}`
                    };
                    const isSaved = await save(patientData, { sessionStatus: sessionStatus }, [], 'assmt_status_change', { source: 'eh_op_widget', page_location: window.location.href, sessionStatus: JSON.stringify(sessionStatus) });
                    if (isSaved) {
                        navigate('/completed', { replace: true, state: { ext_ref_id, status: 'OTHER', description: sessionStatus.prompt} })
                    }
                }
            });

            return
        }

        let prompt = '';
        switch (value) {
            case '0':
                prompt = 'completed'
                break
            case '1':
                prompt = 'patient_no_show'
                break
        }

        swal({
            title: "Are you sure?",
            text: "You will not be allowed to change your responses later. Do you want to proceed?",
            icon: "warning",
            buttons: ["Cancel", "Confirm"],
            dangerMode: true,
        }).then(async (answer: any) => {
            if (answer) {

                let answer: Record<string, any> = {
                    id: value,
                    prompt
                }

                const isSaved = await save(patientData, { sessionStatus: answer }, [], 'assmt_status_change', { source: 'eh_op_widget', page_location: window.location.href, sessionStatus: JSON.stringify(answer) });
                if (isSaved) {
                    navigate('/completed', { replace: true, state: { ext_ref_id, status: 'USERNOSHOW', description: answer.prompt } })
                }
            }
        });
    }

    const handleSaveSurvey = async (questions: Record<string, any>, answers: Record<string, any>, type: 'COMPLETE' | 'DRAFT' | 'IN_PROGRESS', actions: SurveyAction[]) => {

        let answer: Record<string, any> = {
            id: 0,
            prompt: 'completed'
        }

        if (type === 'DRAFT') {
            answer.id = 2;
            answer.prompt = 'draft';

            // Add action close_assessment_draft
            const date = new Date();
            const action: SurveyAction = {
                id: 'close_assessment_draft',
                timestamp: firebase.firestore.Timestamp.fromMillis(date.getTime()),
                category: ActionCategory.status
            }
            actions.push(action);

            const isSaved = await save(patientData, { surveyQuestions: questions, surveyAnswers: answers, sessionStatus: answer }, actions, 'assmt_survey_save', { source: 'eh_op_widget', page_location: window.location.href });
            if (isSaved) {
                navigate('/completed', { replace: true, state: { ext_ref_id, status: 'DRAFT', description: 'Assessment saved as a draft' } })
            }
            return isSaved
        } else if (type === 'IN_PROGRESS') {
            answer.id = 4;
            answer.prompt = 'in_progress';

            const isSaved = prepareAssessmentToSave(patientData, { surveyAnswers: answers, sessionStatus: answer }, actions);

            if (!isSaved) {
                return false
            }

            return true
        }

        // The assessment is complete. Save HAMD and HAMA in answers
        answers['hamd17_total'] = calculateTotalHamd(answers);
        answers['hama14_total'] = calculateTotalHama(answers);

        const isSaved = await save(patientData, { surveyQuestions: questions, surveyAnswers: answers, sessionStatus: answer }, actions, 'assmt_survey_save', { source: 'eh_op_widget', page_location: window.location.href });
        if (isSaved) {
            navigate('/completed', { replace: true, state: { ext_ref_id, status: 'COMPLETED', description: 'Assessment successfully completed' } })
        }
        return isSaved
    }

    const handleSurveyEvent = async (page: number, pageName: string) => {
        // GA event
        const pageObj = {
            index: page,
            pageName,
        }
        logButtonClick(GA_SCREEN_NAMES.ASSESSMENT_PAGE, 'Page', 'assmt_survey_change_page', { source: 'eh_op_widget', page_location: window.location.href, page: JSON.stringify(pageObj) }).logAsFunnelEvent();
    }

    return (
        <div className={classes.root}>
            {loadingSpinner || saveSpinner ?
                <div style={{
                    backgroundColor: '#d7c5fa',
                    width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'
                }}>
                    <div><img alt="" src={logo} height="60" /></div>
                    <CircularProgress />
                </div>
                :
                !ext_ref_id || Object.keys(patientData).length < 1 ?
                    <div style={{
                        backgroundColor: '#d7c5fa',
                        width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2
                    }}>
                        <div><img alt="" src={logo} height="60" /></div>
                    </div>
                    :
                    <Paper id="header" className={classes.paperTopColumn}>
                        <div className={classes.paperTopRow}>
                            <div className={classes.paperTopBox}>
                                <div style={{ display: 'flex' }}>
                                    <Typography variant="body1" style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>Patient Id:</Typography>
                                    <Typography variant="body1" style={{ marginLeft: 10 }}>{ext_ref_id ?? "n/a"}</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <Typography variant="body1" style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>First Name:</Typography>
                                    <Typography variant="body1" style={{ marginLeft: 10 }}>{patientData.userForm?.first_name ?? "n/a"}</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <Typography variant="body1" style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>Last Name:</Typography>
                                    <Typography variant="body1" style={{ marginLeft: 10 }}>{patientData.userForm?.last_name ?? "n/a"}</Typography>
                                </div>
                            </div>

                            <div className={classes.paperTopBox}>
                                <div style={{ display: 'flex' }}>
                                    <Typography variant="body1" style={{ fontWeight: 600 }}>Gender:</Typography>
                                    <Typography variant="body1" style={{ marginLeft: 10 }}>{patientData.userForm?.gender?.toUpperCase() ?? "n/a"}</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <Typography variant="body1" style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>Birth Year:</Typography>
                                    <Typography variant="body1" style={{ marginLeft: 10 }}>{patientData.userForm?.birth_year ?? "n/a"}</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <Typography variant="body1" style={{ fontWeight: 600 }}>State:</Typography>
                                    <Typography variant="body1" style={{ marginLeft: 10 }}>{patientData.userForm?.state?.toUpperCase() ?? "n/a"}</Typography>
                                </div>
                            </div>

                            <div className={classes.paperTopBox} style={{ alignItems: 'center' }}>
                                <div style={{ display: 'flex', marginBottom: 7 }}>
                                    <div className={classes.titleResult}>Session Status</div>
                                    {patientData.sessionStatus?.id === "0" ? <MdCheckCircle size={20} style={{ color: 'green' }} /> : <></>}
                                    {patientData.sessionStatus?.id === "1" || patientData.sessionStatus?.id === "2" ? <MdError size={20} style={{ color: 'red' }} /> : <></>}
                                </div>
                                <div>
                                    <FormControl variant="outlined" style={{ minWidth: 120 }}>
                                        <InputLabel>Select</InputLabel>
                                        <Select
                                            native
                                            value={''}
                                            onChange={handleSessionStatus}
                                            label="Select"
                                        >
                                            <option value="" />
                                            <option disabled value={"0"}>Completed</option>
                                            <option value={"1"}>Patient did not show up</option>
                                            <option value={"3"}>Other</option>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </Paper>
            }

            <div style={{ width: '100%', zIndex: 5, paddingTop: '150px'}}>
                <Collapse in={Object.keys(patientData).length > 0 && !loadingSpinner && !saveSpinner}>
                    <Paper className={classes.paperResult}>
                        <Survey
                            questions={getQuestions(final_survey, patientData.surveyQuestions)}
                            answers={getAnswers(patientData.surveyAnswers)}
                            saveSurvey={handleSaveSurvey}
                            setSurveyEvent={handleSurveyEvent}
                        />
                    </Paper>
                </Collapse>
            </div>

        </div >
    );
};

export default Assessment;
