import React, { FC } from 'react';
import { StylesManager, Model, Question, CustomWidgetCollection, Serializer } from 'survey-core';
import { Survey } from 'survey-react-ui';
import swal from 'sweetalert';
import { SurveyAction, ActionCategory } from '../constants';
import firebase from '../../../config/firebase';

import defaultCustomWidget from './customWidgets/defaultCustomWidget';
import doubleCustomWidget from './customWidgets/doubleCustomWidget';
// Load custom widgets in the surveyJs collection
defaultCustomWidget(CustomWidgetCollection, Serializer);
doubleCustomWidget(CustomWidgetCollection, Serializer);

StylesManager.applyTheme('defaultV2');
interface SurveyComponentProps {
    questions: Record<string, any> | null;
    answers: Record<string, any>;
    saveSurvey: (questions: Record<string, any>, answers: Record<string, any>, type: 'COMPLETE' | 'DRAFT' | 'IN_PROGRESS', actions: SurveyAction[]) => Promise<boolean>;
    setSurveyEvent: (pageNumber: number, name: string) => void;
}

const SurveyComponent: FC<SurveyComponentProps> = ({ questions, answers, saveSurvey, setSurveyEvent }) => {
    const answeredQuestions: Record<string, any> = {};

    if (!questions) return <></>;

    const survey = new Model(questions);
    survey.showCompletedPage = false;

    // If answers are already available, set them inside the survey and change display mode
    if (Object.keys(answers).length) {
        survey.data = answers;
    }

    const handleValueChanged = async (data: any) => {
        const date = new Date();

        const newAction = {
            id: data.name,
            category: ActionCategory.answer,
            answer: data.value,
            timestamp: firebase.firestore.Timestamp.fromMillis(date.getTime()),
        }

        // Add the question name and value to the answeredQuestions map
        answeredQuestions[data.name] = data.value;

        saveSurvey(questions, answeredQuestions, 'IN_PROGRESS', [newAction]);
    };

    function isQuestionVisible(question: any) {
        if (!question || !question.visible) {
            return false;
        }
        return true;
    }

    async function handleComplete(sender: { data: Record<string, any> }) {
        // Do not clear the survey when showing the modal
        survey.clear(false, false);

        // Check if all questions are answered
        const unansweredQuestions = survey.getAllQuestions().filter(question =>
            !question.isAnswered &&
            !question.parent.isReadOnly &&
            isQuestionVisible(question)
        );
        const isSurveyIncomplete = unansweredQuestions.length > 0;
        
        const unansweredQuestionDetails = unansweredQuestions.map(question => question.name);

        // Update confirmation dialog text if there are unanswered questions
        let shouldSaveTitle = 'Assessment survey completed!'
        let shouldSaveText = 'You will not be allowed to change your responses later. Do you want to proceed?';
        if (isSurveyIncomplete) {
            shouldSaveTitle = 'The Assessment is not complete!'
            shouldSaveText = `You have unanswered questions:\n\n${unansweredQuestionDetails.join('\n')}\n\nDo you want to save your assessment survey as a draft?`;

        }

        const shouldSave = await swal({
            title: shouldSaveTitle,
            text: shouldSaveText,
            icon: 'warning',
            buttons: ['Cancel', 'Confirm'],
            dangerMode: true,
        });

        if (shouldSave && questions) {
            const isSaved = await saveSurvey(questions, sender.data, isSurveyIncomplete ? 'DRAFT' : 'COMPLETE', []);
            if (!isSaved) {
                swal({
                    title: 'Error',
                    text: 'An error occurred while saving the survey. Please, contact the support team.',
                    icon: 'error',
                });
            }
        }
    }

    async function handlePageChanged() {
        const page = survey.currentPage;
        if (!page) return;

        const pageNumber = survey.pages?.indexOf(page);
        if (survey.mode !== 'display') {
            setSurveyEvent(pageNumber, page.propertyHash.name);
        }

        window.scrollTo(0, 200);
    }


    survey.onValueChanged.add((survey: Model, options: { name: string, question: Question, value: any }) => {
        const { name, value } = options;
        survey.setValue(name, value);
        handleValueChanged(options);
    });

    survey.onCurrentPageChanged.add(handlePageChanged);
    survey.onComplete.add(handleComplete);

    return <Survey model={survey} />;
};

export default SurveyComponent;
