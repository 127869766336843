/**
 * Check if app is in iframe
 * @returns boolean
 */
export const isIframe = (): boolean => (global.window && (window.location !== window.parent.location));

/**
 * Sends message to parent host if app is hosted in an iframe
 *
 * @param {string} event Event (start | result | error)
 * @param {object} data data related to the event
 */
export const sendMessage = async (
    event: 'start' | 'result' | 'error' | 'end' | 'check_host_focus',
    data?: any,
    allowSendingResult?: boolean,
): Promise<void> => {

    console.log(`communicate::sendMessage > Sending '${event}' event`, data);

    if (!isIframe()) {
        console.log(`communicate::sendMessage > Tried sending '${event}' event, but its not in iframe`);

        return;
    }

    try {
        if (typeof window !== "undefined" && window !== null && 'top' in window && window.top) {
            // NOTE: getting some "proxy" object here, we should convert it to regular JS object;
            // to do that, we end up doing JSON.stringify and then JSON.parse.
            let allowedDomains = '*';
            window.parent.postMessage({ event, data: data ? JSON.parse(JSON.stringify(data)) : undefined }, allowedDomains);
        } else {
            console.warn('communicate::sendMessage > window?.top is not available to send message back to host.');
        }
    } catch (err) {
        console.error(`communicate::sendMessage > Error occurred while sending '${event}' event: ${err}`, err);
    }
};

interface ErrorData {
    status: string;
    message: string;
    interaction_id?: string;
    ref_id?: string;
}

export class MessageService {
    private ext_ref_id: string | null;
    private allowSendingResult?: boolean;

    constructor(ext_ref_id?: string, allowSendingResult?: boolean) {
        this.ext_ref_id = ext_ref_id || null;
        this.allowSendingResult = allowSendingResult;
    }

    sendError(status: string, message?: string): void {
        const data: ErrorData = {
            status,
            message: message ? message : "",
        };

        if (this.ext_ref_id) {
            data.ref_id = this.ext_ref_id;
        }

        this.sendMessage('error', data);
    }

    sendResult(data: any): void {
        this.sendMessage('result', data);
    }

    sendEarlyExit(): void {
        this.sendResult({
            status: 'exitearly',
        });
    }

    /**
     * Sends the message to the host
     *
     * @param {'start'|'error'|'result'} event Event
     * @param {object} message message related to the event
     */
    sendMessage(event: 'start' | 'error' | 'result' | 'end', message?: any): void {
        const currentEpochDate = Math.round(Date.now() / 1000);

        sendMessage(event, {
            timestamp: currentEpochDate,
            // eslint-disable-next-line camelcase
            ref_id: this.ext_ref_id,
            ...message,
        }, this.allowSendingResult);
    }
}

let defMessageService: MessageService | null = null;

type InitDefaultMessageServiceArgs = {
    ext_ref_id?: string;
    allowSendingResult?: boolean;
};

/**
 * Gets a default common message service
 *
 * @param {string} ext_ref_id Ref id
 * @param {string} interactionId Unique interaction id for that session
 * @returns {MessageService} returns the cached default message service
 */
export const initDefaultMessageService = ({
    ext_ref_id,
    allowSendingResult,
}: InitDefaultMessageServiceArgs): MessageService => {
    if (defMessageService) return defMessageService;

    defMessageService = new MessageService(ext_ref_id, allowSendingResult);

    return defMessageService;
};
