import React, { useState } from 'react';
import { Question } from 'survey-core';

interface Column {
    title: string;
    description: string;
}

interface Row {
    title: string;
    description: string;
    cells: Cell[];
}

interface Cell {
    value: number | null;
    columnRef?: string;
}

const AssessmentTable: React.FC<{ name: string, question: Question, description: string, columns: Column[], rows: Row[] }>
    = ({ name, question, description, columns, rows }) => {
        const [selectedValue, setSelectedValue] = useState<{ symptom_intensity: string, frequency: string, value: number } | null>(question.value || null);

        const tableStyle: React.CSSProperties = {
            border: '.75pt solid #000',
            borderCollapse: 'collapse',
            // Custom vendor-prefixed styles using Partial<T>
            ...({
                'AwBorder': '.5pt single',
                'AwBorderInsideh': '.5pt single #000',
                'AwBorderInsidev': '.5pt single #000',
            } as Partial<React.CSSProperties>),
        };

        const headerCellStyle: React.CSSProperties = {
            padding: '5px',
            textAlign: 'center',
            minWidth: '50px'
        };

        const cellStyle: React.CSSProperties = {
            padding: '5px',
            borderStyle: 'solid',
            borderWidth: '.75pt',
            minWidth: '50px'
        };

        const cellStyleWNumber: React.CSSProperties = {
            padding: '5px',
            borderStyle: 'solid',
            borderWidth: '.75pt',
            textAlign: 'center',
            minWidth: '50px'
        };

        const selectedCellStyle: React.CSSProperties = {
            ...cellStyleWNumber,
            backgroundColor: '#19B394', // Change the background color for selected cells
        };

        const grayCellStyle: React.CSSProperties = {
            ...cellStyle,
            backgroundColor: 'grey',
            minWidth: '15px'
        };

        const textStyle: React.CSSProperties = {
            fontSize: '8pt',
            fontFamily: 'Calibri',
            whiteSpace: 'pre-wrap', // Preserve line breaks and spaces
        };

        const smallTextStyle: React.CSSProperties = {
            fontSize: '7pt',
            fontFamily: 'Calibri',
            whiteSpace: 'pre-wrap', // Preserve line breaks and spaces
        };

        const getStyleForCell = (row: string, col: string): React.CSSProperties => {
            const isCellSelected = selectedValue && selectedValue.symptom_intensity === row && selectedValue.frequency === col;
            return isCellSelected ? selectedCellStyle : cellStyleWNumber;
        };

        const handleClick = (symptom_intensity: string, frequency: string, value: number) => {
            setSelectedValue({ symptom_intensity, frequency, value });
            // Update the survey response when a cell is selected
            question.value = { symptom_intensity, frequency, value };
        };

        return (
            <div style={{ width: '100%', overflow: 'auto' }}>
                <table style={tableStyle}>
                    <tbody style={{ width: '100%' }}>

                        <tr style={{ height: '10pt' }}>
                            {columns.length > 1 ? <td style={headerCellStyle}></td> : <></>}
                            <td style={headerCellStyle}>
                                <p style={textStyle}>
                                    Frequency
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td style={cellStyle}>
                                <p style={textStyle}>
                                    {description}
                                </p>
                            </td>
                            {columns.map((column: Column) =>
                            (
                                <td key={`${name}_${column.title}`} style={cellStyle}>
                                    <p style={textStyle}>
                                        <b>{column.title}</b>
                                    </p>
                                    <p style={smallTextStyle}>{column.description}</p>
                                </td>
                            ))}
                        </tr>
                        <tr style={{ height: '15pt' }}>
                            <td style={{ ...headerCellStyle, backgroundColor: '#d1cade' }}>
                                <p style={textStyle}>
                                    Symptom Intensity
                                </p>
                            </td>
                            {columns.map(() =>
                            (
                                <td style={headerCellStyle}></td>
                            ))}
                        </tr>
                        {rows.map((row) => (
                            <tr key={`${name}_${row.title}`} style={{ height: '15pt' }}>
                                <td style={cellStyle}>
                                    <p style={textStyle}>
                                        <b>{row.title}</b>
                                    </p>
                                    <p style={smallTextStyle}>{row.description}</p>
                                </td>
                                {
                                    row.cells.map((cell, index) => (
                                        <td key={`${name}_${row.title}_${index}`} id={`depression_mood_${index}_${row.title}_${cell.columnRef}`}
                                            style={cell.value || cell.value === 0 ? getStyleForCell(row.title, cell.columnRef ?? "") : grayCellStyle}
                                            onClick={() => cell.value || cell.value === 0 ? handleClick(row.title, cell.columnRef ?? "", cell.value) : {}}
                                        >
                                            {cell.value}
                                        </td>
                                    ))
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

export default AssessmentTable;
