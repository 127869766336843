import firebase from '../../config/firebase';

export const final_survey = {
    "logoPosition": "right",
    "focusFirstQuestionAutomatic": false,
    "pages": [
        {
            "name": "page1",
            "elements": [
                {
                    "type": "panel",
                    "name": "panel1",
                    "elements": [
                        {
                            "type": "text",
                            "name": "first_name",
                            "title": "First Name",
                            "isRequired": true
                        },
                        {
                            "type": "text",
                            "name": "last_name",
                            "title": "Last Name",
                            "isRequired": true
                        }
                    ]
                }
            ],
            "title": "Confirm user's name and last name",
            "description": "Please, ask the user's name and last name to double check you are talking with the expected user. Input the info below ",
            "navigationTitle": "User's info"
        },
        {
            "name": "page2",
            "elements": [
                {
                    "type": "custom_assessment_widget",
                    "name": "hamd1_depressed_mood",
                    "title": "Depressed Mood",
                    "customProperties": {
                        "description": "This item assesses feelings of sadness, hopelessness, helplessness, and worthlessness. Note: This is not a global rating of depressive illness.",
                        "columns": [
                            {
                                "title": "Absent",
                                "description": "Not occurring or clinically insignificant"
                            },
                            {
                                "title": "Occasional",
                                "description": "Infrequent; less than 3 days; up to 30% of the week"
                            },
                            {
                                "title": "Much of the time",
                                "description": "Often; 3-5 days; 31%-75% of the week"
                            },
                            {
                                "title": "Almost all of the time",
                                "description": "Persistent; 6-7 days; more than 75% of the week"
                            }
                        ],
                        "rows": [
                            {
                                "title": "Absent",
                                "description": "",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": null }, { "value": null }, { "value": null }]
                            },
                            {
                                "title": "Mild",
                                "description": "Feelings of sadness, discouragement, low self-esteem, pessimism",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": 1, "columnRef": "Occasional" }, { "value": 1, "columnRef": "Much of the time" }, { "value": 2, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Moderate",
                                "description": "Clear nonverbal signs of sadness (such as tearfulness), feelings of hopelessness, helplessness, or worthlessness about some aspects of life",
                                cells: [{ "value": null }, { "value": 1, "columnRef": "Occasional" }, { "value": 2, "columnRef": "Much of the time" }, { "value": 3, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Severe",
                                "description": "Intense sadness, weeping, hopelessness about most aspects of life, feelings of complete helplessness or worthlessness",
                                cells: [{ "value": null }, { "value": 2, "columnRef": "Occasional" }, { "value": 3, "columnRef": "Much of the time" }, { "value": 4, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Very severe",
                                "description": "Extreme sadness, intractable hopelessness or helplessness",
                                cells: [{ "value": null }, { "value": 3, "columnRef": "Occasional" }, { "value": 4, "columnRef": "Much of the time" }, { "value": 4, "columnRef": "Almost all of the time" }]
                            }
                        ]
                    }
                }, {
                    "type": "custom_assessment_widget",
                    "name": "hamd2_guilt",
                    "title": "Guilt",
                    "customProperties": {
                        "description": "Guilt is defined as the sense of having done something bad or wrong and is accompanied by feelings of regret or shame. Guilt is rated only if it is excessive or unrealistic.",
                        "columns": [
                            {
                                "title": "Absent",
                                "description": "Not occurring or clinically insignificant"
                            },
                            {
                                "title": "Occasional",
                                "description": "Infrequent; less than 3 days; up to 30% of the week"
                            },
                            {
                                "title": "Much of the time",
                                "description": "Often; 3-5 days; 31%-75% of the week"
                            },
                            {
                                "title": "Almost all of the time",
                                "description": "Persistent; 6-7 days; more than 75% of the week"
                            }
                        ],
                        "rows": [
                            {
                                "title": "Absent",
                                "description": "",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": null }, { "value": null }, { "value": null }]
                            },
                            {
                                "title": "Mild",
                                "description": "Self-critical, self-reproach (e.g., “I’ve let people down.”)",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": 1, "columnRef": "Occasional" }, { "value": 1, "columnRef": "Much of the time" }, { "value": 2, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Moderate",
                                "description": "Feelings of guilt, remorse, shame; belief that one has done something bad or wrong",
                                cells: [{ "value": null }, { "value": 1, "columnRef": "Occasional" }, { "value": 2, "columnRef": "Much of the time" }, { "value": 3, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Severe",
                                "description": "Pervasive feelings of guilt; feels the illness is a punishment for sinful deeds",
                                cells: [{ "value": null }, { "value": 2, "columnRef": "Occasional" }, { "value": 3, "columnRef": "Much of the time" }, { "value": 4, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Very severe",
                                "description": "Delusions, hallucinations",
                                cells: [{ "value": null }, { "value": null }, { "value": 4, "columnRef": "Much of the time" }, { "value": 4, "columnRef": "Almost all of the time" }]
                            }
                        ]
                    }
                }, {
                    "type": "custom_assessment_widget",
                    "name": "hamd3_suicide",
                    "title": "Suicide",
                    "customProperties": {
                        "description": "This item assess the full range of severity of suicidal ideation and behavior.",
                        "columns": [
                            {
                                "title": "Absent",
                                "description": "Not occurring or clinically insignificant"
                            },
                            {
                                "title": "Occasional",
                                "description": "Infrequent; less than 3 days; up to 30% of the week"
                            },
                            {
                                "title": "Much of the time",
                                "description": "Often; 3-5 days; 31%-75% of the week"
                            },
                            {
                                "title": "Almost all of the time",
                                "description": "Persistent; 6-7 days; more than 75% of the week"
                            }
                        ],
                        "rows": [
                            {
                                "title": "Absent",
                                "description": "",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": null }, { "value": null }, { "value": null }]
                            },
                            {
                                "title": "Mild",
                                "description": "Feels life is not worth living, but expresses no wish to die (e.g., “I don’t care if I live or die”)",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": 1, "columnRef": "Occasional" }, { "value": 1, "columnRef": "Much of the time" }, { "value": 2, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Moderate",
                                "description": "Wishes to be dead; thoughts of dying, but no specific plan or intent (e.g., “If I got hit by a bus, I wouldn’t care,” “I’d like to go to sleep and never wake up”)",
                                cells: [{ "value": null }, { "value": 1, "columnRef": "Occasional" }, { "value": 2, "columnRef": "Much of the time" }, { "value": 3, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Severe",
                                "description": "Clear suicidal plan or intent; suicidal gesture (e.g., taking a few sleeping pills)",
                                cells: [{ "value": null }, { "value": 3, "columnRef": "Occasional" }, { "value": 3, "columnRef": "Much of the time" }, { "value": 4, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Very severe",
                                "description": "Attempts at suicide",
                                cells: [{ "value": null }, { "value": 4, "columnRef": "Occasional" }, { "value": 4, "columnRef": "Much of the time" }, { "value": 4, "columnRef": "Almost all of the time" }]
                            }
                        ]
                    }
                }, {
                    "type": "custom_assessment_widget",
                    "name": "hamd4_insomnia_early",
                    "title": "Insomnia Early",
                    "customProperties": {
                        "description": "Time to first falling asleep",
                        "columns": [
                            {
                                "title": "Absent",
                                "description": "Not occurring or clinically insignificant"
                            },
                            {
                                "title": "Occasional",
                                "description": "Infrequent; less than 3 days; up to 30% of the week"
                            },
                            {
                                "title": "Much of the time",
                                "description": "Often; 3-5 days; 31%-75% of the week"
                            },
                            {
                                "title": "Almost all of the time",
                                "description": "Persistent; 6-7 days; more than 75% of the week"
                            }
                        ],
                        "rows": [
                            {
                                "title": "Absent",
                                "description": "",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": null }, { "value": null }, { "value": null }]
                            },
                            {
                                "title": "Mild",
                                "description": "30-59 minutes to fall asleep",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": 1, "columnRef": "Occasional" }, { "value": 1, "columnRef": "Much of the time" }, { "value": 2, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Marked",
                                "description": "1h or more to fall asleep",
                                cells: [{ "value": null }, { "value": 1, "columnRef": "Occasional" }, { "value": 2, "columnRef": "Much of the time" }, { "value": 2, "columnRef": "Almost all of the time" }]
                            }
                        ]
                    }
                }, {
                    "type": "custom_assessment_widget",
                    "name": "hamd5_insomnia_middle",
                    "title": "Insomnia Middle",
                    "customProperties": {
                        "description": "After falling asleep and until 2 hours prior to the usual hour of waking",
                        "columns": [
                            {
                                "title": "Absent",
                                "description": "Not occurring or clinically insignificant"
                            },
                            {
                                "title": "Occasional",
                                "description": "Infrequent; less than 3 days; up to 30% of the week"
                            },
                            {
                                "title": "Much of the time",
                                "description": "Often; 3-5 days; 31%-75% of the week"
                            },
                            {
                                "title": "Almost all of the time",
                                "description": "Persistent; 6-7 days; more than 75% of the week"
                            }
                        ],
                        "rows": [
                            {
                                "title": "Absent",
                                "description": "",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": null }, { "value": null }, { "value": null }]
                            },
                            {
                                "title": "Mild",
                                "description": "30-59 minutes to fall asleep",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": 1, "columnRef": "Occasional" }, { "value": 1, "columnRef": "Much of the time" }, { "value": 2, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Marked",
                                "description": "1h or more to fall asleep",
                                cells: [{ "value": null }, { "value": 1, "columnRef": "Occasional" }, { "value": 2, "columnRef": "Much of the time" }, { "value": 2, "columnRef": "Almost all of the time" }]
                            }
                        ]
                    }
                }, {
                    "type": "custom_assessment_widget",
                    "name": "hamd6_insomnia_late",
                    "title": "Insomnia Late",
                    "customProperties": {
                        "description": "Within 2 hours of usual hour of waking. May stay awake during this time frame or may return to sleep after full awakening.",
                        "columns": [
                            {
                                "title": "Absent",
                                "description": "Not occurring or clinically insignificant"
                            },
                            {
                                "title": "Occasional",
                                "description": "Infrequent; less than 3 days; up to 30% of the week"
                            },
                            {
                                "title": "Much of the time",
                                "description": "Often; 3-5 days; 31%-75% of the week"
                            },
                            {
                                "title": "Almost all of the time",
                                "description": "Persistent; 6-7 days; more than 75% of the week"
                            }
                        ],
                        "rows": [
                            {
                                "title": "Absent",
                                "description": "",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": null }, { "value": null }, { "value": null }]
                            },
                            {
                                "title": "Mild",
                                "description": "30-59 minutes to fall asleep",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": 1, "columnRef": "Occasional" }, { "value": 1, "columnRef": "Much of the time" }, { "value": 2, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Marked",
                                "description": "1h or more to fall asleep",
                                cells: [{ "value": null }, { "value": 1, "columnRef": "Occasional" }, { "value": 2, "columnRef": "Much of the time" }, { "value": 2, "columnRef": "Almost all of the time" }]
                            }
                        ]
                    }
                }, {
                    "type": "custom_assessment_widget",
                    "name": "hamd7_work_and_activities",
                    "title": "Work and Activities",
                    "customProperties": {
                        "description": "This item assesses loss of interest or pleasure and impairment in functioning at work inside and outside the home, leisure activities, and family and social relationships.",
                        "columns": [
                            {
                                "title": "Absent",
                                "description": "Not occurring or clinically insignificant"
                            },
                            {
                                "title": "Occasional",
                                "description": "Infrequent; less than 3 days; up to 30% of the week"
                            },
                            {
                                "title": "Much of the time",
                                "description": "Often; 3-5 days; 31%-75% of the week"
                            },
                            {
                                "title": "Almost all of the time",
                                "description": "Persistent; 6-7 days; more than 75% of the week"
                            }
                        ],
                        "rows": [
                            {
                                "title": "Absent",
                                "description": "",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": null }, { "value": null }, { "value": null }]
                            },
                            {
                                "title": "Mild",
                                "description": "Some reduction in interest or pleasure but no clear impairment in functioning",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": 1, "columnRef": "Occasional" }, { "value": 1, "columnRef": "Much of the time" }, { "value": 2, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Moderate",
                                "description": "Significant reduction in interest or pleasure or clear impairment in functioning",
                                cells: [{ "value": null }, { "value": 1, "columnRef": "Occasional" }, { "value": 2, "columnRef": "Much of the time" }, { "value": 3, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Severe",
                                "description": "Profound reduction in interest, pleasure, and functioning.",
                                cells: [{ "value": null }, { "value": 2, "columnRef": "Occasional" }, { "value": 3, "columnRef": "Much of the time" }, { "value": 4, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Very severe",
                                "description": "Unable to work; needs help performing self-care activities; unable to function without assistance",
                                cells: [{ "value": null }, { "value": 3, "columnRef": "Occasional" }, { "value": 4, "columnRef": "Much of the time" }, { "value": 4, "columnRef": "Almost all of the time" }]
                            }
                        ]
                    }
                }, {
                    "type": "custom_assessment_widget",
                    "name": "hamd8_psychomotor_retardation",
                    "title": "Psychomotor Retardation",
                    "customProperties": {
                        "description": "This item assesses retardation in movement and speech observed during interview.",
                        "columns": [
                            {
                                "title": "",
                                "description": ""
                            }
                        ],
                        "rows": [
                            {
                                "title": "Absent",
                                "description": "",
                                cells: [{ "value": 0, "columnRef": "" }]
                            },
                            {
                                "title": "Mild",
                                "description": "Rate of speech slightly reduced",
                                cells: [{ "value": 1, "columnRef": "" }]
                            },
                            {
                                "title": "Moderate",
                                "description": "Rate of speech clearly reduced with noticeable pauses",
                                cells: [{ "value": 2, "columnRef": "" }]
                            },
                            {
                                "title": "Severe",
                                "description": "Interview clearly prolonged; all movements very slowed",
                                cells: [{ "value": 3, "columnRef": "" }]
                            },
                            {
                                "title": "Very severe",
                                "description": "Interview cannot be completed",
                                cells: [{ "value": 4, "columnRef": "" }]
                            },
                        ]
                    }
                }, {
                    "type": "custom_assessment_widget",
                    "name": "hamd9_psychomotor_agitation",
                    "title": "Psychomotor Agitation",
                    "customProperties": {
                        "description": "This item assesses agitation in motor behavior and speech observed during interview.",
                        "columns": [
                            {
                                "title": "",
                                "description": ""
                            }
                        ],
                        "rows": [
                            {
                                "title": "Absent",
                                "description": "Movements within normal range (e.g., occasionally shifts position in seat)",
                                cells: [{ "value": 0, "columnRef": "" }]
                            },
                            {
                                "title": "Mild",
                                "description": "Doubtful or slight agitation, mild restlessness (e.g., frequently changing position in seat, foot-tapping, playing with hair, hands, or clothes)",
                                cells: [{ "value": 1, "columnRef": "" }]
                            },
                            {
                                "title": "Moderate",
                                "description": "Moderate to marked restlessness or agitation (e.g., wring-ing hands, excessive scratching or picking)",
                                cells: [{ "value": 2, "columnRef": "" }]
                            },
                            {
                                "title": "Severe",
                                "description": "Cannot sit still or stay seated even for a short period of time; pacing",
                                cells: [{ "value": 3, "columnRef": "" }]
                            },
                            {
                                "title": "Very severe",
                                "description": "Interview cannot be completed",
                                cells: [{ "value": 4, "columnRef": "" }]
                            },
                        ]
                    }
                }, {
                    "type": "custom_assessment_widget",
                    "name": "hamd10_anxiety_psychic",
                    "title": "Anxiety, Psychic",
                    "customProperties": {
                        "description": "This item assesses apprehension, fear, panic, and worry, as well as irritability. Note: Do not rate physical symptoms of panic attacks here. Rate in item 11: Anxiety, Somatic.",
                        "columns": [
                            {
                                "title": "Absent",
                                "description": "Not occurring or clinically insignificant"
                            },
                            {
                                "title": "Occasional",
                                "description": "Infrequent; less than 3 days; up to 30% of the week"
                            },
                            {
                                "title": "Much of the time",
                                "description": "Often; 3-5 days; 31%-75% of the week"
                            },
                            {
                                "title": "Almost all of the time",
                                "description": "Persistent; 6-7 days; more than 75% of the week"
                            }
                        ],
                        "rows": [
                            {
                                "title": "Absent",
                                "description": "",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": null }, { "value": null }, { "value": null }]
                            },
                            {
                                "title": "Mild",
                                "description": "Some feelings of worry or irritability",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": 1, "columnRef": "Occasional" }, { "value": 1, "columnRef": "Much of the time" }, { "value": 2, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Moderate",
                                "description": "Excessive worry or irritability; anxiety causes distress; may cause some impairment in functioning",
                                cells: [{ "value": null }, { "value": 1, "columnRef": "Occasional" }, { "value": 2, "columnRef": "Much of the time" }, { "value": 3, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Severe",
                                "description": "Pervasive worry or dread; fearing the worst; apprehension obvious in demeanor or behavior; significant impairment in functioning; feelings of panic",
                                cells: [{ "value": null }, { "value": 2, "columnRef": "Occasional" }, { "value": 3, "columnRef": "Much of the time" }, { "value": 4, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Very severe",
                                "description": "Incapacitating",
                                cells: [{ "value": null }, { "value": 3, "columnRef": "Occasional" }, { "value": 4, "columnRef": "Much of the time" }, { "value": 4, "columnRef": "Almost all of the time" }]
                            }
                        ]
                    }
                }, {
                    "type": "custom_assessment_widget",
                    "name": "hamd11_anxiety_somatic",
                    "title": "Anxiety, Somatic",
                    "customProperties": {
                        "description": "This item assesses physical symptoms associated with anxiety. Gastrointestinal - dry mouth, gas, indigestion, diarrhea, constipation, stomach cramps, belching. Cardiovascular - heart pounding or racing. Respiratory - sighing, hyperventilation. Other - headaches, urinary frequency, sweating, lightheadedness",
                        "columns": [
                            {
                                "title": "Absent",
                                "description": "Not occurring or clinically insignificant"
                            },
                            {
                                "title": "Occasional",
                                "description": "Infrequent; less than 3 days; up to 30% of the week"
                            },
                            {
                                "title": "Much of the time",
                                "description": "Often; 3-5 days; 31%-75% of the week"
                            },
                            {
                                "title": "Almost all of the time",
                                "description": "Persistent; 6-7 days; more than 75% of the week"
                            }
                        ],
                        "rows": [
                            {
                                "title": "Absent",
                                "description": "",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": null }, { "value": null }, { "value": null }]
                            },
                            {
                                "title": "Mild",
                                "description": "Some distress",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": 1, "columnRef": "Occasional" }, { "value": 1, "columnRef": "Much of the time" }, { "value": 2, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Moderate",
                                "description": "Marked distress, may cause some impairment in functioning",
                                cells: [{ "value": null }, { "value": 1, "columnRef": "Occasional" }, { "value": 2, "columnRef": "Much of the time" }, { "value": 3, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Severe",
                                "description": "Significant impairment in functioning",
                                cells: [{ "value": null }, { "value": 2, "columnRef": "Occasional" }, { "value": 3, "columnRef": "Much of the time" }, { "value": 4, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Very severe",
                                "description": "Incapacitating",
                                cells: [{ "value": null }, { "value": 3, "columnRef": "Occasional" }, { "value": 4, "columnRef": "Much of the time" }, { "value": 4, "columnRef": "Almost all of the time" }]
                            }
                        ]
                    }
                }, {
                    "type": "custom_assessment_widget",
                    "name": "hamd12_loss_of_appetite",
                    "title": "Loss of Appetite (Somatic Symptoms, Gastrointestinal)",
                    "customProperties": {
                        "description": "(Somatic Symptoms, Gastrointestinal) This item assesses appetite (e.g., hunger, desire for food, enjoyment of food). Note: Do not rate other gastrointestinal symp- toms here. Rate in item 11: Anxiety, Somatic.",
                        "columns": [
                            {
                                "title": "Absent",
                                "description": "Not occurring or clinically insignificant"
                            },
                            {
                                "title": "Occasional",
                                "description": "Infrequent; less than 3 days; up to 30% of the week"
                            },
                            {
                                "title": "Much of the time",
                                "description": "Often; 3-5 days; 31%-75% of the week"
                            },
                            {
                                "title": "Almost all of the time",
                                "description": "Persistent; 6-7 days; more than 75% of the week"
                            }
                        ],
                        "rows": [
                            {
                                "title": "Absent",
                                "description": "",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": null }, { "value": null }, { "value": null }]
                            },
                            {
                                "title": "Mild",
                                "description": "Some loss of appetite but eating without encouragement; less interest or pleasure in eating",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": 1, "columnRef": "Occasional" }, { "value": 1, "columnRef": "Much of the time" }, { "value": 1, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Marked",
                                "description": "Marked loss of appetite, very little interest or pleasure in eating (e.g., forcing self to eat)",
                                cells: [{ "value": null }, { "value": 1, "columnRef": "Occasional" }, { "value": 2, "columnRef": "Much of the time" }, { "value": 2, "columnRef": "Almost all of the time" }]
                            }
                        ]
                    }
                }, {
                    "type": "custom_assessment_widget",
                    "name": "hamd13_somatic_symptoms_general",
                    "title": "Somatic Symptoms, General",
                    "customProperties": {
                        "description": "This item assesses tiredness, loss of energy, fatigue, and muscular aches and pains.",
                        "columns": [
                            {
                                "title": "Absent",
                                "description": "Not occurring or clinically insignificant"
                            },
                            {
                                "title": "Occasional",
                                "description": "Infrequent; less than 3 days; up to 30% of the week"
                            },
                            {
                                "title": "Much of the time",
                                "description": "Often; 3-5 days; 31%-75% of the week"
                            },
                            {
                                "title": "Almost all of the time",
                                "description": "Persistent; 6-7 days; more than 75% of the week"
                            }
                        ],
                        "rows": [
                            {
                                "title": "Absent",
                                "description": "",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": null }, { "value": null }, { "value": null }]
                            },
                            {
                                "title": "Mild",
                                "description": "Mild tiredness, loss of energy, fatigue, feelings of heaviness in limbs or being weighted down, or muscular aches or pains",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": 1, "columnRef": "Occasional" }, { "value": 1, "columnRef": "Much of the time" }, { "value": 1, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Marked",
                                "description": "Prominent tiredness, loss of energy, fatigue, feelings of heaviness in limbs or being weighted down, or muscular aches or pains",
                                cells: [{ "value": null }, { "value": 1, "columnRef": "Occasional" }, { "value": 2, "columnRef": "Much of the time" }, { "value": 2, "columnRef": "Almost all of the time" }]
                            }
                        ]
                    }
                }, {
                    "type": "custom_assessment_widget",
                    "name": "hamd14_sexual_interest",
                    "title": "Sexual Interest (Genital Symptoms)",
                    "customProperties": {
                        "description": "This item assesses loss of interest or pleasure in sex; not amount of activity.",
                        "columns": [
                            {
                                "title": "",
                                "description": ""
                            }
                        ],
                        "rows": [
                            {
                                "title": "Absent",
                                "description": "",
                                cells: [{ "value": 0, "columnRef": "" }]
                            },
                            {
                                "title": "Mild",
                                "description": "Some loss of interest or pleasure",
                                cells: [{ "value": 1, "columnRef": "" }]
                            },
                            {
                                "title": "Marked",
                                "description": "Marked loss of interest or pleasure",
                                cells: [{ "value": 2, "columnRef": "" }]
                            }
                        ]
                    }
                }, {
                    "type": "custom_assessment_widget",
                    "name": "hamd15_hypochondriasis",
                    "title": "Hypochondriasis",
                    "customProperties": {
                        "description": "This item assesses unjustified preoccupation with having a general medical illness regardless of whether it is present or not.",
                        "columns": [
                            {
                                "title": "Absent",
                                "description": "Not occurring or clinically insignificant"
                            },
                            {
                                "title": "Occasional",
                                "description": "Infrequent; less than 3 days; up to 30% of the week"
                            },
                            {
                                "title": "Much of the time",
                                "description": "Often; 3-5 days; 31%-75% of the week"
                            },
                            {
                                "title": "Almost all of the time",
                                "description": "Persistent; 6-7 days; more than 75% of the week"
                            }
                        ],
                        "rows": [
                            {
                                "title": "Absent",
                                "description": "",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": null }, { "value": null }, { "value": null }]
                            },
                            {
                                "title": "Mild",
                                "description": "Preoccupation with bodily functions and sensations, but no concerns about a specific illness",
                                cells: [{ "value": 0, "columnRef": "Absent" }, { "value": 1, "columnRef": "Occasional" }, { "value": 1, "columnRef": "Much of the time" }, { "value": 2, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Moderate",
                                "description": "Excessive or unrealistic worry about having an illness (e.g., “I worry that these headaches are from a brain tumor”).",
                                cells: [{ "value": null }, { "value": 1, "columnRef": "Occasional" }, { "value": 2, "columnRef": "Much of the time" }, { "value": 3, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Severe",
                                "description": "Strong unrealistic conviction of having an illness (e.g., “I am convinced I have cancer”).",
                                cells: [{ "value": null }, { "value": 2, "columnRef": "Occasional" }, { "value": 3, "columnRef": "Much of the time" }, { "value": 3, "columnRef": "Almost all of the time" }]
                            },
                            {
                                "title": "Very severe",
                                "description": "Somatic delusions or hallucinations (e.g., “My insides are rotting”).",
                                cells: [{ "value": null }, { "value": 4, "columnRef": "Occasional" }, { "value": 4, "columnRef": "Much of the time" }, { "value": 4, "columnRef": "Almost all of the time" }]
                            }
                        ]
                    }
                }, {
                    "type": "custom_assessment_double_widget",
                    "name": "hamd16_loss_of_weight",
                    "title": "Loss of Weight",
                    "description": "Do not rate weight loss due to dieting and non-depression-related circumstances (e.g., weight loss due to general medical conditions).",
                    "customProperties": {
                        "topTitle": "A. When rating by history. At initial assessment, compare to premorbid weight. At follow-up, compare to previous visit.",
                        "bottomTitle": "",
                        "topColumns": [
                            {
                                "title": "",
                                "description": ""
                            }
                        ],
                        "topRows": [
                            {
                                "title": "No weight loss",
                                "description": "",
                                cells: [{ "value": 0, "columnRef": "" }]
                            },
                            {
                                "title": "Probable weight loss",
                                "description": "",
                                cells: [{ "value": 1, "columnRef": "" }]
                            },
                            {
                                "title": "Definite weight loss",
                                "description": "",
                                cells: [{ "value": 2, "columnRef": "" }]
                            }
                        ],
                        "bottomColumns": [],
                        "bottomRows": []
                    }
                }, {
                    "type": "custom_assessment_widget",
                    "name": "hamd17_insight",
                    "title": "Insight",
                    "customProperties": {
                        "description": "This item assesses pathological denial of illness. Do not rate denial that reflects cultural norms.",
                        "columns": [
                            {
                                "title": "",
                                "description": ""
                            }
                        ],
                        "rows": [
                            {
                                "title": "Absent",
                                "description": "Any recognition of depressive symptoms, with or without attribution to any cause (e.g., “I’m de- pressed because my partner always argues with me”).",
                                cells: [{ "value": 0, "columnRef": "" }]
                            },
                            {
                                "title": "Mild",
                                "description": "Denies illness but accepts possibility of being ill (e.g., “I don’t think there’s anything wrong, but other people think there is”).",
                                cells: [{ "value": 1, "columnRef": "" }]
                            },
                            {
                                "title": "Marked",
                                "description": "Complete denial of having illness (e.g., “I’m not depressed; I’m fine”).",
                                cells: [{ "value": 2, "columnRef": "" }]
                            }
                        ]
                    }
                }
            ],
            "title": "Hamilton Depression Rating Scale (HAM-D)",
            "navigationTitle": "HAM-D"
        },
        {
            "name": "page3",
            "elements": [
                {
                    "type": "radiogroup",
                    "name": "hama1_anxious_mood",
                    "title": "Anxious mood: Worries, anticipation of the worst, fearful anticipation, irritability.",
                    "choices": [
                        {
                            "value": 0,
                            "text": "Not Present"
                        },
                        {
                            "value": 1,
                            "text": "Mild"
                        },
                        {
                            "value": 2,
                            "text": "Moderate"
                        },
                        {
                            "value": 3,
                            "text": "Severe"
                        },
                        {
                            "value": 4,
                            "text": "Very Severe"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "hama2_tension",
                    "title": "Tension: Feelings of tension, fatigability, startle response, moved to tears easily, trembling, feelings of restlessness, inability to relax.",
                    "choices": [
                        {
                            "value": 0,
                            "text": "Not Present"
                        },
                        {
                            "value": 1,
                            "text": "Mild"
                        },
                        {
                            "value": 2,
                            "text": "Moderate"
                        },
                        {
                            "value": 3,
                            "text": "Severe"
                        },
                        {
                            "value": 4,
                            "text": "Very Severe"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "hama3_fears",
                    "title": "Fears: Of dark, of strangers, of being left alone, of animals, of traffic, of crowds.",
                    "choices": [
                        {
                            "value": 0,
                            "text": "Not Present"
                        },
                        {
                            "value": 1,
                            "text": "Mild"
                        },
                        {
                            "value": 2,
                            "text": "Moderate"
                        },
                        {
                            "value": 3,
                            "text": "Severe"
                        },
                        {
                            "value": 4,
                            "text": "Very Severe"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "hama4_insomnia",
                    "title": "Insomnia: Difficulty in falling asleep, broken sleep, unsatisfying sleep and fatigue on waking, dreams, nightmares, night terrors.",
                    "choices": [
                        {
                            "value": 0,
                            "text": "Not Present"
                        },
                        {
                            "value": 1,
                            "text": "Mild"
                        },
                        {
                            "value": 2,
                            "text": "Moderate"
                        },
                        {
                            "value": 3,
                            "text": "Severe"
                        },
                        {
                            "value": 4,
                            "text": "Very Severe"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "hama5_intellectual",
                    "title": "Intellectual: Difficulty in concentration, poor memory.",
                    "choices": [
                        {
                            "value": 0,
                            "text": "Not Present"
                        },
                        {
                            "value": 1,
                            "text": "Mild"
                        },
                        {
                            "value": 2,
                            "text": "Moderate"
                        },
                        {
                            "value": 3,
                            "text": "Severe"
                        },
                        {
                            "value": 4,
                            "text": "Very Severe"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "hama6_depressed_mood",
                    "title": "Depressed mood: Loss of interest, lack of pleasure in hobbies, depression, early waking, diurnal swing",
                    "choices": [
                        {
                            "value": 0,
                            "text": "Not Present"
                        },
                        {
                            "value": 1,
                            "text": "Mild"
                        },
                        {
                            "value": 2,
                            "text": "Moderate"
                        },
                        {
                            "value": 3,
                            "text": "Severe"
                        },
                        {
                            "value": 4,
                            "text": "Very Severe"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "hama7_somatic_muscular",
                    "title": "Somatic (muscular): Pains and aches, twitching, stiffness, myoclonic jerks, grinding of teeth, unsteady voice, increased muscular tone.",
                    "choices": [
                        {
                            "value": 0,
                            "text": "Not Present"
                        },
                        {
                            "value": 1,
                            "text": "Mild"
                        },
                        {
                            "value": 2,
                            "text": "Moderate"
                        },
                        {
                            "value": 3,
                            "text": "Severe"
                        },
                        {
                            "value": 4,
                            "text": "Very Severe"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "hama8_somatic_sensory",
                    "title": "Somatic (sensory): Tinnitus, blurring of vision, hot and cold flushes, feelings of weakness, pricking sensation.",
                    "choices": [
                        {
                            "value": 0,
                            "text": "Not Present"
                        },
                        {
                            "value": 1,
                            "text": "Mild"
                        },
                        {
                            "value": 2,
                            "text": "Moderate"
                        },
                        {
                            "value": 3,
                            "text": "Severe"
                        },
                        {
                            "value": 4,
                            "text": "Very Severe"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "hama9_cardiovascular_symptoms",
                    "title": "Cardiovascular symptoms: Tachycardia, palpitations, pain in chest, throbbing of vessels, fainting feelings, missing beat.",
                    "choices": [
                        {
                            "value": 0,
                            "text": "Not Present"
                        },
                        {
                            "value": 1,
                            "text": "Mild"
                        },
                        {
                            "value": 2,
                            "text": "Moderate"
                        },
                        {
                            "value": 3,
                            "text": "Severe"
                        },
                        {
                            "value": 4,
                            "text": "Very Severe"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "hama10_respiratory_symptoms",
                    "title": "Respiratory symptoms: Pressure or constriction in chest, choking feelings, sighing, dyspnea.",
                    "choices": [
                        {
                            "value": 0,
                            "text": "Not Present"
                        },
                        {
                            "value": 1,
                            "text": "Mild"
                        },
                        {
                            "value": 2,
                            "text": "Moderate"
                        },
                        {
                            "value": 3,
                            "text": "Severe"
                        },
                        {
                            "value": 4,
                            "text": "Very Severe"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "hama11_gastrointestinal_symptoms",
                    "title": "Gastrointestinal symptoms: Difficulty in swallowing, wind abdominal pain, burning sensations, abdominal fullness, nausea, vomiting, borborygmi, looseness of bowels, loss of weight, constipation.",
                    "choices": [
                        {
                            "value": 0,
                            "text": "Not Present"
                        },
                        {
                            "value": 1,
                            "text": "Mild"
                        },
                        {
                            "value": 2,
                            "text": "Moderate"
                        },
                        {
                            "value": 3,
                            "text": "Severe"
                        },
                        {
                            "value": 4,
                            "text": "Very Severe"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "hama12_genitourinary_symptoms",
                    "title": "Genitourinary symptoms: Frequency of micturition, urgency of micturition, amenorrhea, menorrhagia, development of frigidity, premature ejaculation, loss of libido, impotence.",
                    "choices": [
                        {
                            "value": 0,
                            "text": "Not Present"
                        },
                        {
                            "value": 1,
                            "text": "Mild"
                        },
                        {
                            "value": 2,
                            "text": "Moderate"
                        },
                        {
                            "value": 3,
                            "text": "Severe"
                        },
                        {
                            "value": 4,
                            "text": "Very Severe"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "hama13_autonomic_symptoms",
                    "title": "Autonomic symptoms: Dry mouth, flushing, pallor, tendency to sweat, giddiness, tension headache, raising of hair.",
                    "choices": [
                        {
                            "value": 0,
                            "text": "Not Present"
                        },
                        {
                            "value": 1,
                            "text": "Mild"
                        },
                        {
                            "value": 2,
                            "text": "Moderate"
                        },
                        {
                            "value": 3,
                            "text": "Severe"
                        },
                        {
                            "value": 4,
                            "text": "Very Severe"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "hama14_behavior_at_interview",
                    "title": "Behavior at interview: Fidgeting, restlessness or pacing, tremor of hands, furrowed brow, strained face, sighing or rapid respiration, facial pallor, swallowing, etc.",
                    "choices": [
                        {
                            "value": 0,
                            "text": "Not Present"
                        },
                        {
                            "value": 1,
                            "text": "Mild"
                        },
                        {
                            "value": 2,
                            "text": "Moderate"
                        },
                        {
                            "value": 3,
                            "text": "Severe"
                        },
                        {
                            "value": 4,
                            "text": "Very Severe"
                        }
                    ]
                }
            ],
            "title": "Hamilton Anxiety Scale",
            "description": "Rates level of anxiety based on clinical questions.",
            "navigationTitle": "HAM-A"
        },
        {
            "name": "page4",
            "elements": [
                {
                    "type": "panel",
                    "name": "mini_section_a1_panel1",
                    "elements": [
                        {
                            "type": "boolean",
                            "name": "1_mini_section_a1a",
                            "title": "A1 a: Were you ever depressed or down, or did you feel sad, empty or hopeless, most of the day, nearly every day, for two weeks?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "2_mini_section_a1b",
                            "enableIf": "{mini_section_a1b_visibility} = 'yes'",
                            "title": "A1 b: For the past two weeks, were you depressed or down, or did you feel sad, empty or hopeless, most of the day, nearly every day?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        }
                    ]
                },
                {
                    "type": "panel",
                    "name": "mini_section_a2_panel2",
                    "elements": [
                        {
                            "type": "boolean",
                            "name": "3_mini_section_a2a",
                            "title": "A2 a: Were you ever much less interested in most things or much less able to enjoy the things you used to enjoy most of the time, for two weeks?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "4_mini_section_a2b",
                            "enableIf": "{mini_section_a2b_visibility} = 'yes'",
                            "title": "A2 b: In the past 2 weeks, were you much less interested in most things or much less able to enjoy the things you used to enjoy, most of the time?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        }
                    ]
                },
                {
                    "type": "panel",
                    "name": "mini_section_a",
                    "elements": [
                        {
                            "type": "panel",
                            "name": "mini_section_a3_panel3",
                            "elements": [
                                {
                                    "type": "boolean",
                                    "name": "5_mini_section_a3a_past_two_weeks",
                                    "visibleIf": "({1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes') and({2_mini_section_a1b} = 'yes' or {4_mini_section_a2b} = 'yes')",
                                    "title": "Past Two Weeks",
                                    "hideNumber": true,
                                    "defaultValue": "n/a",
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                },
                                {
                                    "type": "boolean",
                                    "name": "6_mini_section_a3a_past_episode",
                                    "visibleIf": "{1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes'",
                                    "title": "Past Episode",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                }
                            ],
                            "title": "A3 a: Over that two-week period, when you felt depressed or uninterested:",
                            "description": "Was your appetite decreased or increased nearly every day? Did your weight decrease or increase without trying intentionally (i.e. by +- 5% of body weight or +- 8lb or +- 3.5kg, for a 160lb/70kg person in a month)?",
                            "visibleIf": "{1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes'"
                        },
                        {
                            "type": "panel",
                            "name": "mini_section_a3_panel4",
                            "elements": [
                                {
                                    "type": "boolean",
                                    "name": "7_mini_section_a3b_past_two_weeks",
                                    "visibleIf": "({1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes') and({2_mini_section_a1b} = 'yes' or {4_mini_section_a2b} = 'yes')",
                                    "title": "Past 2 Weeks",
                                    "hideNumber": true,
                                    "defaultValue": "n/a",
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                },
                                {
                                    "type": "boolean",
                                    "name": "8_mini_section_a3b_past_episode",
                                    "visibleIf": "{1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes'",
                                    "title": "Past Episode",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                }
                            ],
                            "title": "A3 b: Over that two-week period, when you felt depressed or uninterested:",
                            "description": "Did you have trouble sleeping nearly every night (difficulty falling asleep, waking up in the middle of the night, early morning wakening or sleeping excessively)?",
                            "visibleIf": "{1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes'"
                        },
                        {
                            "type": "panel",
                            "name": "mini_section_a3_panel5",
                            "elements": [
                                {
                                    "type": "boolean",
                                    "name": "9_mini_section_a3c_past_two_weeks",
                                    "visibleIf": "({1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes') and({2_mini_section_a1b} = 'yes' or {4_mini_section_a2b} = 'yes')",
                                    "title": "Past 2 Weeks",
                                    "hideNumber": true,
                                    "defaultValue": "n/a",
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                },
                                {
                                    "type": "boolean",
                                    "name": "10_mini_section_a3c_past_episode",
                                    "title": "Past Episode",
                                    "visibleIf": "{1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes'",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                }
                            ],
                            "title": "A3 c: Over that two-week period, when you felt depressed or uninterested:",
                            "description": "Did you talk or move more slowly than normal or were you fidgety, restless or having trouble sitting still almost every day? Did anyone notice this?",
                            "visibleIf": "{1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes'"
                        },
                        {
                            "type": "panel",
                            "name": "mini_section_a3_panel6",
                            "elements": [
                                {
                                    "type": "boolean",
                                    "name": "11_mini_section_a3d_past_two_weeks",
                                    "visibleIf": "({1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes') and({2_mini_section_a1b} = 'yes' or {4_mini_section_a2b} = 'yes')",
                                    "title": "Past 2 Weeks",
                                    "hideNumber": true,
                                    "defaultValue": "n/a",
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                },
                                {
                                    "type": "boolean",
                                    "name": "12_mini_section_a3d_past_episode",
                                    "visibleIf": "{1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes'",
                                    "title": "Past Episode",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                }
                            ],
                            "title": "A3 d: Over that two-week period, when you felt depressed or uninterested:",
                            "description": "Did you feel tired or without energy almost every day?",
                            "visibleIf": "{1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes'",
                        },
                        {
                            "type": "panel",
                            "name": "mini_section_a3_panel7",
                            "elements": [
                                {
                                    "type": "boolean",
                                    "name": "13_mini_section_a3e_past_two_weeks",
                                    "visibleIf": "({1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes') and({2_mini_section_a1b} = 'yes' or {4_mini_section_a2b} = 'yes')",
                                    "title": "Past 2 Weeks",
                                    "hideNumber": true,
                                    "defaultValue": "n/a",
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                },
                                {
                                    "type": "boolean",
                                    "name": "14_mini_section_a3e_past_episode",
                                    "visibleIf": "{1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes'",
                                    "title": "Past Episode",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                }
                            ],
                            "title": "A3 e: Over that two-week period, when you felt depressed or uninterested:",
                            "description": "Did you feel worthless or guilty almost every day?",
                            "visibleIf": "{1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes'"
                        },
                        {
                            "type": "panel",
                            "name": "mini_section_a3_panel8",
                            "elements": [
                                {
                                    "type": "boolean",
                                    "name": "15_mini_section_a3e2_past_two_weeks",
                                    "visibleIf": "({1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes') and({13_mini_section_a3e_past_two_weeks} = 'yes' or {14_mini_section_a3e_past_episode} = 'yes')",
                                    "title": "Past 2 Weeks",
                                    "hideNumber": true,
                                    "defaultValue": "n/a",
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                },
                                {
                                    "type": "boolean",
                                    "name": "16_mini_section_a3e2_past_episode",
                                    "visibleIf": "({1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes') and({13_mini_section_a3e_past_two_weeks} = 'yes' or {14_mini_section_a3e_past_episode} = 'yes')",
                                    "title": "Past Episode",
                                    "hideNumber": true,
                                    "defaultValue": "n/a",
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                }
                            ],
                            "visibleIf": "({1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes') and({13_mini_section_a3e_past_two_weeks} = 'yes' or {14_mini_section_a3e_past_episode} = 'yes')",
                            "description": "A3 e2: ASK FOR EXAMPLES, LOOK FOR DELUSIONS OR FAILURE, OR INADEQUACY, OF RUIN OR OF GUILT, OR OF NEEDING PUNISHMENT OR DELUSIONS OF DISEASE OR DEATH OR NIHILISTIC OR SOMATIC DELUSIONS. THE EXAMPLES ARE CONSISTENT WITH A DELUSIONAL IDEA."
                        },
                        {
                            "type": "panel",
                            "name": "mini_section_a3_panel9",
                            "elements": [
                                {
                                    "type": "boolean",
                                    "name": "17_mini_section_a3f_past_two_weeks",
                                    "visibleIf": "({1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes') and({2_mini_section_a1b} = 'yes' or {4_mini_section_a2b} = 'yes')",
                                    "title": "Past 2 Weeks",
                                    "hideNumber": true,
                                    "defaultValue": "n/a",
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                },
                                {
                                    "type": "boolean",
                                    "name": "18_mini_section_a3f_past_episode",
                                    "visibleIf": "{1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes'",
                                    "title": "Past Episode",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                }
                            ],
                            "title": "A3 f: Over that two-week period, when you felt depressed or uninterested:",
                            "description": "Did you have difficulty concentrating, thinking or making decisions almost every day?",
                            "visibleIf": "{1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes'"
                        },
                        {
                            "type": "panel",
                            "name": "mini_section_a3_panel10",
                            "elements": [
                                {
                                    "type": "boolean",
                                    "name": "19_mini_section_a3g_past_two_weeks",
                                    "visibleIf": "({1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes') and({2_mini_section_a1b} = 'yes' or {4_mini_section_a2b} = 'yes')",
                                    "title": "Past 2 Weeks",
                                    "hideNumber": true,
                                    "defaultValue": "n/a",
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                },
                                {
                                    "type": "boolean",
                                    "name": "20_mini_section_a3g_past_episode",
                                    "visibleIf": "{1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes'",
                                    "title": "Past Episode",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                }
                            ],
                            "title": "A3 g: Over that two-week period, when you felt depressed or uninterested:",
                            "description": "Did you repeatedly think about death (FEAR OF DYING DOES NOT COUNT HERE), or have any thoughts of killing yourself, or have any intent or plan to kill yourself? Did you attempt suicide?",
                            "visibleIf": "{1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes'",
                        },
                        {
                            "type": "panel",
                            "name": "mini_section_a4_panel11",
                            "elements": [
                                {
                                    "type": "boolean",
                                    "name": "21_mini_section_a4_past_two_weeks",
                                    "visibleIf": "{1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes'",
                                    "title": "Past 2 Weeks",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                },
                                {
                                    "type": "boolean",
                                    "name": "22_mini_section_a4_past_episode",
                                    "visibleIf": "{1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes'",
                                    "title": "Past Episode",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                }
                            ],
                            "title": "A4: Did these symptoms cause significant distress or problems at home, at work, at school, socially, in your relationships, or in some other important way, and are they a change from your previous functioning?",
                            "visibleIf": "{1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes'",
                        },
                        {
                            "type": "panel",
                            "name": "mini_section_a5_panel12",
                            "elements": [
                                {
                                    "type": "boolean",
                                    "name": "23_mini_section_a5_past_episode",
                                    "title": "Past Episode",
                                    "visibleIf": "{1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes'",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                }
                            ],
                            "title": "A5: In between 2 episodes of depression, did you ever have an interval of at least 2 months, without any significant depression or any significant loss of interest?",
                            "visibleIf": "{1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes'"
                        },
                        {
                            "type": "panel",
                            "name": "mini_section_a6_panel13",
                            "elements": [
                                {
                                    "type": "text",
                                    "name": "24_mini_section_a6a",
                                    "title": "A6: How many episodes of depression did you have in your lifetime?",
                                    "description": "Between each episode there must be at least 2 months without any significant depression.",
                                    "visibleIf": "{1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes'",
                                    "hideNumber": true,
                                    "inputType": "number",
                                    "min": 0,
                                    "max": 100
                                }
                            ]
                        }
                    ],
                    "visibleIf": "{1_mini_section_a1a} = 'yes' or {3_mini_section_a2a} = 'yes'"
                }
            ],
            "title": "MINI - MAJOR DEPRESSIVE EPISODE"
        },
        {
            "name": "page5",
            "elements": [
                {
                    "type": "panel",
                    "name": "mini_section_c_panel1",
                    "elements": [
                        {
                            "type": "boolean",
                            "name": "25_mini_section_c_q1",
                            "title": "q1: Do you have any family history of manic-depressive illness or bipolar disorder, or any family member who had mood swings treated with a medication like lithium, sodium valproate (Depakote) or lamotrigine (Lamictal)?",
                            "description": "THIS QUESTION IS NOT A CRITERIUM FOR BIPOLAR DISORDER, BUT IS ASKED TO INCREASE THE CLINICIAN'S VIGILANCE ABOUT THE RISK FOR BIPOLAR DISORDER.",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "text",
                            "name": "26_mini_section_c_q2",
                            "visibleIf": "{25_mini_section_c_q1} = 'yes'",
                            "title": "q2: IF YES, PLEASE SPECIFY WHO:"
                        }
                    ]
                },
                {
                    "type": "panel",
                    "name": "mini_section_c_panel2",
                    "elements": [
                        {
                            "type": "boolean",
                            "name": "27_mini_section_c1a",
                            "title": "C1 a: Have you ever had a period of time when you were feeling 'up' or 'high' or 'hyper' and so active or full of energy or full of yourself that you got into trouble, - or that other people thought you were not your usual self? (Do not consider times when you were intoxicated on drugs or alcohol.)",
                            "description": "IF PATIENT IS PUZZLED OR UNCLEAR ABOUT WHAT YOU MEAN BY 'UP' OR 'HIGH' OR 'HYPER', CLARIFY AS FOLLOWS: By 'up' or 'high' or 'hyper' I mean: having elated mood; increased energy or increased activity; needing less sleep; having rapid thoughts; being full of ideas; having an increase in productivity, motivation, creativity, or impulsive behavior; phoning or working excessively or spending more money",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "28_mini_section_c1b",
                            "title": "C1 b: Are you currently feeling 'up' or 'high' or 'hyper' or full of energy?",
                            "hideNumber": true,
                            "enableIf": "{mini_section_c1b_visibility} = 'yes'",
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        }
                    ]
                },
                {
                    "type": "panel",
                    "name": "mini_section_c_panel3",
                    "elements": [
                        {
                            "type": "boolean",
                            "name": "29_mini_section_c2a",
                            "title": "C2 a: Have you ever been persistently irritable, for several days, so that you had arguments or verbal or physical fights, or shouted at people outside your family? Have you or others noticed that you have been more irritable or over reacted, compared to other people, even in situations that you felt were justified?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "30_mini_section_c2b",
                            "title": "C2 b: Are you currently feeling persistently irritable?",
                            "hideNumber": true,
                            "enableIf": "{mini_section_c2b_visibility} = 'yes'",
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        }
                    ]
                },
                {
                    "type": "panel",
                    "name": "mini_section_c_mainpanel",
                    "elements": [
                        {
                            "type": "panel",
                            "name": "mini_section_c3_panel4",
                            "elements": [
                                {
                                    "type": "boolean",
                                    "name": "31_mini_section_c3a_current_episode",
                                    "visibleIf": "({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and ({28_mini_section_c1b} = 'yes' or {30_mini_section_c2b} = 'yes')",
                                    "title": "Current Episode",
                                    "description": "Over the past few days including today, when you felt high and full of energy or irritable, did you: Feel that you could do things others couldn't do, or that you were an especially important person?",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                },
                                {
                                    "type": "boolean",
                                    "name": "32_mini_section_c3a2_current_episode",
                                    "visibleIf": "({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and {31_mini_section_c3a_current_episode} = 'yes' and ({28_mini_section_c1b} = 'yes' or {30_mini_section_c2b} = 'yes')",
                                    "title": "Current Episode - examples",
                                    "description": "ASK FOR EXAMPLES. THE EXAMPLES ARE CONSISTENT WITH A DELUSIONAL IDEA.",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                },
                                {
                                    "type": "boolean",
                                    "name": "33_mini_section_c3a_past_episode",
                                    "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'",
                                    "title": "Past Episode",
                                    "description": "Over a period of a few days in the past, when you felt most high and most full of energy or most irritable, did you: Feel that you could do things others couldn't do, or that you were an especially important person?",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                },
                                {
                                    "type": "boolean",
                                    "name": "34_mini_section_c3a2_past_episode",
                                    "visibleIf": "({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and{33_mini_section_c3a_past_episode} = 'yes'",
                                    "title": "Past Episode",
                                    "description": "ASK FOR EXAMPLES. THE EXAMPLES ARE CONSISTENT WITH A DELUSIONAL IDEA.",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                }
                            ],
                            "title": "C3 a",
                            "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'"
                        },
                        {
                            "type": "panel",
                            "name": "mini_section_c3_panel5",
                            "elements": [
                                {
                                    "type": "boolean",
                                    "name": "35_mini_section_c3b_current_episode",
                                    "visibleIf": "({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and({28_mini_section_c1b} = 'yes' or {30_mini_section_c2b} = 'yes')",
                                    "title": "Current Episode",
                                    "description": "Over the past few days including today, when you felt high and full of energy or irritable, did you: Need less sleep (for example feel rested after only a few hours of sleep)?",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                },
                                {
                                    "type": "boolean",
                                    "name": "36_mini_section_c3b_past_episode",
                                    "visibleIf": "({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes')",
                                    "title": "Past Episode",
                                    "description": "Over a period of a few days in the past, when you felt most high and most full of energy or most irritable, did you: Need less sleep (for example feel rested after only a few hours of sleep)?",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                }
                            ],
                            "title": "C3 b",
                            "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'"
                        },
                        {
                            "type": "panel",
                            "name": "mini_section_c3_panel6",
                            "elements": [
                                {
                                    "type": "boolean",
                                    "name": "37_mini_section_c3c_current_episode",
                                    "visibleIf": "({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and({28_mini_section_c1b} = 'yes' or {30_mini_section_c2b} = 'yes')",
                                    "title": "Current Episode",
                                    "description": "Over the past few days including today, when you felt high and full of energy or irritable, did you: Talk too much without stopping, or felt a pressure to keep talking?",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                },
                                {
                                    "type": "boolean",
                                    "name": "38_mini_section_c3c_past_episode",
                                    "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'",
                                    "title": "Past Episode",
                                    "description": "Over a period of a few days in the past, when you felt most high and most full of energy or most irritable, did you: Talk too much without stopping, or felt a pressure to keep talking?",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                }
                            ],
                            "title": "C3 c",
                            "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'"
                        },
                        {
                            "type": "panel",
                            "name": "mini_section_c3_panel7",
                            "elements": [
                                {
                                    "type": "boolean",
                                    "name": "39_mini_section_c3d_current_episode",
                                    "visibleIf": "({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and({28_mini_section_c1b} = 'yes' or {30_mini_section_c2b} = 'yes')",
                                    "title": "Current Episode",
                                    "description": "Over the past few days including today, when you felt high and full of energy or irritable, did you: Notice your thoughts going very fast or running together or racing or moving very quickly from one subject to another?",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                },
                                {
                                    "type": "boolean",
                                    "name": "40_mini_section_c3d_past_episode",
                                    "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'",
                                    "title": "Past Episode",
                                    "description": "Over a period of a few days in the past, when you felt most high and most full of energy or most irritable, did you: Notice your thoughts going very fast or running together or racing or moving very quickly from one subject to another?",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                }
                            ],
                            "title": "C3 d",
                            "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'"
                        },
                        {
                            "type": "panel",
                            "name": "mini_section_c3_panel8",
                            "elements": [
                                {
                                    "type": "boolean",
                                    "name": "41_mini_section_c3e_current_episode",
                                    "visibleIf": "({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and({28_mini_section_c1b} = 'yes' or {30_mini_section_c2b} = 'yes')",
                                    "title": "Current Episode",
                                    "description": "Over the past few days including today, when you felt high and full of energy or irritable, did you: Become easily distracted so that any little interruption could distract you?",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                },
                                {
                                    "type": "boolean",
                                    "name": "42_mini_section_c3e_past_episode",
                                    "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'",
                                    "title": "Past Episode",
                                    "description": "Over a period of a few days in the past, when you felt most high and most full of energy or most irritable, did you: Become easily distracted so that any little interruption could distract you?",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                }
                            ],
                            "title": "C3 e",
                            "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'"
                        },
                        {
                            "type": "panel",
                            "name": "mini_section_c3_panel9",
                            "elements": [
                                {
                                    "type": "boolean",
                                    "name": "43_mini_section_c3f_current_episode",
                                    "visibleIf": "({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and({28_mini_section_c1b} = 'yes' or {30_mini_section_c2b} = 'yes')",
                                    "title": "Current Episode",
                                    "description": "Over the past few days including today, when you felt high and full of energy or irritable, did you: Have a significant increase in your activity or drive, at work, at school, socially or sexually or did you become physically or mentally restless? This increase in activity may be with or without a purpose.",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                },
                                {
                                    "type": "boolean",
                                    "name": "44_mini_section_c3f_past_episode",
                                    "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'",
                                    "title": "Past Episode",
                                    "description": "Over a period of a few days in the past, when you felt most high and most full of energy or most irritable, did you: Have a significant increase in your activity or drive, at work, at school, socially or sexually or did you become physically or mentally restless? This increase in activity may be with or without a purpose.",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                }
                            ],
                            "title": "C3 f",
                            "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'"
                        },
                        {
                            "type": "panel",
                            "name": "mini_section_c3_panel10",
                            "elements": [
                                {
                                    "type": "boolean",
                                    "name": "45_mini_section_c3g_current_episode",
                                    "visibleIf": "({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and({28_mini_section_c1b} = 'yes' or {30_mini_section_c2b} = 'yes')",
                                    "title": "Current Episode",
                                    "description": "Over the past few days including today, when you felt high and full of energy or irritable, did you: Want so much to engage in pleasurable activities that you ignored the risks or consequences (for example, spending sprees, reckless driving, or sexual indiscretions)?",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                },
                                {
                                    "type": "boolean",
                                    "name": "46_mini_section_c3g_past_episode",
                                    "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'",
                                    "title": "Past Episode",
                                    "description": "Over a period of a few days in the past, when you felt most high and most full of energy or most irritable, did you: Want so much to engage in pleasurable activities that you ignored the risks or consequences (for example, spending sprees, reckless driving, or sexual indiscretions)?",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                }
                            ],
                            "title": "C3 g",
                            "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'"
                        },
                        {
                            "type": "panel",
                            "name": "mini_section_c4_panel12",
                            "elements": [
                                {
                                    "type": "panel",
                                    "name": "mini_section_c4a_panel12",
                                    "elements": [
                                        {
                                            "type": "boolean",
                                            "name": "47_mini_section_c4a_current_episode",
                                            "visibleIf": "({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and ({28_mini_section_c1b} = 'yes' or {30_mini_section_c2b} = 'yes')",
                                            "title": "Current Episode",
                                            "hideNumber": true,
                                            "valueTrue": "yes",
                                            "valueFalse": "no"
                                        },
                                        {
                                            "type": "boolean",
                                            "name": "48_mini_section_c4a_past_episode",
                                            "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'",
                                            "title": "Past Episode",
                                            "hideNumber": true,
                                            "valueTrue": "yes",
                                            "valueFalse": "no"
                                        }
                                    ],
                                    "title": "Three consecutive days or less",
                                    "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'"
                                },
                                {
                                    "type": "panel",
                                    "name": "mini_section_c4b_panel12",
                                    "elements": [
                                        {
                                            "type": "boolean",
                                            "name": "49_mini_section_c4b_current_episode",
                                            "visibleIf": "({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and ({28_mini_section_c1b} = 'yes' or {30_mini_section_c2b} = 'yes')",
                                            "title": "Current Episode",
                                            "hideNumber": true,
                                            "valueTrue": "yes",
                                            "valueFalse": "no"
                                        },
                                        {
                                            "type": "boolean",
                                            "name": "50_mini_section_c4b_past_episode",
                                            "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'",
                                            "title": "Past Episode",
                                            "hideNumber": true,
                                            "valueTrue": "yes",
                                            "valueFalse": "no"
                                        }
                                    ],
                                    "title": "4,5 or 6 consecutive days, or more",
                                    "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'"
                                },
                                {
                                    "type": "panel",
                                    "name": "mini_section_c4c_panel12",
                                    "elements": [
                                        {
                                            "type": "boolean",
                                            "name": "51_mini_section_c4c_current_episode",
                                            "visibleIf": "({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and ({28_mini_section_c1b} = 'yes' or {30_mini_section_c2b} = 'yes')",
                                            "title": "Current Episode",
                                            "hideNumber": true,
                                            "valueTrue": "yes",
                                            "valueFalse": "no"
                                        },
                                        {
                                            "type": "boolean",
                                            "name": "52_mini_section_c4c_past_episode",
                                            "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'",
                                            "title": "Past Episode",
                                            "hideNumber": true,
                                            "valueTrue": "yes",
                                            "valueFalse": "no"
                                        }
                                    ],
                                    "title": "7 consecutive days, or more",
                                    "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'"
                                }
                            ],
                            "title": "C4: What is the longest time these symptoms lasted (most of the day nearly every day)?",
                            "description": "ASSESS THIS DURATION FROM THE VERY START TO THE VERY END OF SYMPTOMS, NOT JUST THE PEAK",
                            "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'"
                        },
                        {
                            "type": "panel",
                            "name": "mini_section_c5_panel13",
                            "elements": [
                                {
                                    "type": "boolean",
                                    "name": "53_mini_section_c5_current_episode",
                                    "visibleIf": "({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and ({28_mini_section_c1b} = 'yes' or {30_mini_section_c2b} = 'yes')",
                                    "title": "Current Episode",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                },
                                {
                                    "type": "boolean",
                                    "name": "54_mini_section_c5_past_episode",
                                    "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'",
                                    "title": "Past Episode",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                }
                            ],
                            "title": "C5: Were you hospitalized for these problems?",
                            "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'"
                        },
                        {
                            "type": "panel",
                            "name": "mini_section_c6_panel13",
                            "elements": [
                                {
                                    "type": "boolean",
                                    "name": "55_mini_section_c6_current_episode",
                                    "visibleIf": "({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and({28_mini_section_c1b} = 'yes' or {30_mini_section_c2b} = 'yes') and {53_mini_section_c5_current_episode} = 'no'",
                                    "title": "Current Episode",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                },
                                {
                                    "type": "boolean",
                                    "name": "56_mini_section_c6_past_episode",
                                    "visibleIf": "({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and{54_mini_section_c5_past_episode} = 'no'",
                                    "title": "Past Episode",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                }
                            ],
                            "title": "C6: Did these symptoms cause significant problems at home, at work, socially, in your relationships, at school or in some other important way?",
                            "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'"
                        },
                        {
                            "type": "panel",
                            "name": "mini_section_c7_panel14",
                            "elements": [
                                {
                                    "type": "boolean",
                                    "name": "57_mini_section_c7_current_episode",
                                    "visibleIf": "({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and({28_mini_section_c1b} = 'yes' or {30_mini_section_c2b} = 'yes')",
                                    "title": "Current Episode",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                },
                                {
                                    "type": "boolean",
                                    "name": "58_mini_section_c7_past_episode",
                                    "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'",
                                    "title": "Past Episode",
                                    "hideNumber": true,
                                    "valueTrue": "yes",
                                    "valueFalse": "no"
                                }
                            ],
                            "title": "C7: Were these symptoms associated with a clear change in the way that you previously functioned and that was different from the way that you usually are?",
                            "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'"
                        }
                    ],
                    "visibleIf": "{27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes'"
                }
            ],
            "title": "MINI SECTION C - MANIC AND HYPOMANIC EPISODES"
        },
        {
            "name": "page6",
            "elements": [
                {
                    "type": "panel",
                    "name": "59_mini_section_k_intro",
                    "title": "\" Now I'm going to ask you about unusual experiences that some people have. \""
                },
                {
                    "type": "boolean",
                    "name": "60_mini_section_k1a",
                    "title": "K1 a: Have you ever believed that people were spying on you, or that someone was plotting against you, or trying to hurt you?",
                    "description": "NOTE: ASK FOR EXAMPLES TO RULE OUT ACTUAL STALKING",
                    "hideNumber": true,
                    "valueTrue": "yes",
                    "valueFalse": "no"
                },
                {
                    "type": "boolean",
                    "name": "61_mini_section_k1b",
                    "visibleIf": "{60_mini_section_k1a} = 'yes'",
                    "title": "K1 b: Do you currently believe these things?",
                    "hideNumber": true,
                    "valueTrue": "yes",
                    "valueFalse": "no"
                },
                {
                    "type": "boolean",
                    "name": "62_mini_section_k2a",
                    "title": "K2 a: Have you ever believed that someone was reading your mind or could hear your thoughts, or that you could actually read someone's mind or hear what another person was thinking?",
                    "hideNumber": true,
                    "valueTrue": "yes",
                    "valueFalse": "no"
                },
                {
                    "type": "boolean",
                    "name": "63_mini_section_k2b",
                    "visibleIf": "{62_mini_section_k2a} = 'yes'",
                    "title": "K2 b: Do you currently believe these things?",
                    "hideNumber": true,
                    "valueTrue": "yes",
                    "valueFalse": "no"
                },
                {
                    "type": "boolean",
                    "name": "64_mini_section_k3a",
                    "title": "K3 a: Have you ever believed that someone or some force outside of yourself put thoughts in your mind that were not your own, or made you act in a way that was not your usual self? Have you ever felt that you were possessed?",
                    "description": "CLINICIAN: ASK FOR EXAMPLES AND DISCOUNT ANY THAT ARE NOT PSYCHOTIC",
                    "hideNumber": true,
                    "valueTrue": "yes",
                    "valueFalse": "no"
                },
                {
                    "type": "boolean",
                    "name": "65_mini_section_k3b",
                    "visibleIf": "{64_mini_section_k3a} = 'yes'",
                    "title": "K3 b: Do you currently believe these things?",
                    "hideNumber": true,
                    "valueTrue": "yes",
                    "valueFalse": "no"
                },
                {
                    "type": "boolean",
                    "name": "66_mini_section_k4a",
                    "title": "K4 a: Have you ever believed that you were being sent special messages through the TV, radio, internet, newspaper, books, or magazines or that a person you did not personally know was particularly interested in you?",
                    "hideNumber": true,
                    "valueTrue": "yes",
                    "valueFalse": "no"
                },
                {
                    "type": "boolean",
                    "name": "67_mini_section_k4b",
                    "visibleIf": "{66_mini_section_k4a} = 'yes'",
                    "title": "K4 b: Do you currently believe these things?",
                    "hideNumber": true,
                    "valueTrue": "yes",
                    "valueFalse": "no"
                },
                {
                    "type": "boolean",
                    "name": "68_mini_section_k5a",
                    "title": "K5 a: Have your relatives or friends ever considered any of your beliefs odd or unusual?",
                    "description": "CLINICIAN: ASK FOR EXAMPLES. ONLY CODE YES IF THE EXAMPLES ARE CLEARLY DELUSIONAL IDEAS NOT EXPLORED IN QUESTIONS K1 TO K4. FOR EXAMPLE, RELIGIOUS, DEATH, DISEASE OR SOMATIC DELUSIONS, DELUSIONS OF GRANDIOSITY, JEALOUSY OR GUILT, OR OF FAILURE, INADEQUACY, RUIN, OR DESTITUTION, OR NIHILISTIC DELUSIONS.",
                    "hideNumber": true,
                    "valueTrue": "yes",
                    "valueFalse": "no"
                },
                {
                    "type": "boolean",
                    "name": "69_mini_section_k5b",
                    "visibleIf": "{68_mini_section_k5a} = 'yes'",
                    "title": "K5 b: Do they currently consider your beliefs strange or unusual?",
                    "hideNumber": true,
                    "valueTrue": "yes",
                    "valueFalse": "no"
                },
                {
                    "type": "boolean",
                    "name": "70_mini_section_k6a",
                    "title": "K6 a: Have you ever heard things other people couldn't hear, such as voices?",
                    "hideNumber": true,
                    "valueTrue": "yes",
                    "valueFalse": "no"
                },
                {
                    "type": "boolean",
                    "name": "71_mini_section_k6a2",
                    "visibleIf": "{70_mini_section_k6a} = 'yes'",
                    "title": "K6 a2: Was the voice commenting on your thoughts or behavior or did you hear two or more voices talking to each other?",
                    "hideNumber": true,
                    "valueTrue": "yes",
                    "valueFalse": "no"
                },
                {
                    "type": "boolean",
                    "name": "72_mini_section_k6b",
                    "visibleIf": "{70_mini_section_k6a} = 'yes'",
                    "title": "K6 b: Have you heard sounds / voices in the past month?",
                    "hideNumber": true,
                    "valueTrue": "yes",
                    "valueFalse": "no"
                },
                {
                    "type": "boolean",
                    "name": "73_mini_section_k6b2",
                    "visibleIf": "{70_mini_section_k6a} = 'yes' and {72_mini_section_k6b} = 'yes'",
                    "title": "K6 b2: Was the voice commenting on your thoughts or behavior or did you hear two or more voices talking to each other?",
                    "hideNumber": true,
                    "valueTrue": "yes",
                    "valueFalse": "no"
                },
                {
                    "type": "boolean",
                    "name": "74_mini_section_k7a",
                    "title": "K7 a: Have your ever had visions when you were awake or have you ever seen things other people couldn't see?",
                    "description": "CLINICIAN: CHECK TO SEE IF THESE ARE CULTURALLY INAPPROPRIATE.",
                    "hideNumber": true,
                    "valueTrue": "yes",
                    "valueFalse": "no"
                },
                {
                    "type": "boolean",
                    "name": "75_mini_section_k7b",
                    "visibleIf": "{74_mini_section_k7a} = 'yes'",
                    "title": "K7 b: Have you seen these things in the past month?",
                    "hideNumber": true,
                    "valueTrue": "yes",
                    "valueFalse": "no"
                }
            ],
            "title": "MINI SECTION K - PSYCHOTIC DISORDERS AND MOOD DISORDER WITH PSYCHOTIC FEATURES",
            "description": "ASK FOR AN EXAMPLE OF EACH QUESTION ANSWERED POSITEVELY, CODE YES ONLY IF THE EXAMPLES CLEARLY SHOW A DISTORTION OF THOUGHT OR OF PERCEPTION OR IF THEY ARE NOT CULTURALLY APPROPRIATE. THE PURPOSE OF THIS MODULE IS TO EXCLUDE PATIENTS WITH PSYCHOTIC DISORDERS. THIS MODULE NEEDS EXPERIENCE."
        },
        {
            "name": "page7",
            "elements": [
                {
                    "type": "panel",
                    "name": "K8_to_K11_panel1",
                    "title": "CLINICIAN'S JUDGMENT",
                    "elements": [
                        {
                            "type": "boolean",
                            "name": "76_mini_section_k8a",
                            "title": "K8 a: DID THE PATIENT EVER IN THE PAST EXHIBIT DISORGANIZED, INCOHERENT OR DERAILED SPEECH, OR MARKED LOOSENING OF ASSOCIATIONS?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "77_mini_section_k8b",
                            "title": "K8 b: IS THE PATIENT CURRENTLY EXHIBITING INCOHERENCE, DISORGANIZED OR DERAILED SPEECH, OR MARKED LOOSENING OF ASSOCIATIONS?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "78_mini_section_k9a",
                            "title": "K9 a: DID THE PATIENT EVER IN THE PAST EXHIBIT DISORGANIZED OR CATATONIC BEHAVIOR?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "79_mini_section_k9b",
                            "title": "K9 b: IS THE PATIENT CURRENTLY EXHIBITING DISORGANIZED OR CATATONIC BEHAVIOR?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "80_mini_section_k10a",
                            "title": "K10 a: DID THE PATIENT EVER IN THE PAST HAVE NEGATIVE SYMPTOMS OF SCHIZOPHRENIA, E.G. SIGNIFICANT REDUCTION OF EMOTIONAL EXPRESSION OR AFFECTIVE FLATTENING, POVERTY OF SPEECH (ALOGIA) OR AN INABILITY TO INITIATE OR PERSIST IN GOAL-DIRECTED ACTIVITIES (AVOLITION)?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "81_mini_section_k10b",
                            "title": "K10 b: ARE NEGATIVE SYMPTOMS OF SCHIZOPHRENIA, E.G. SIGNIFICANT REDUCTION OF EMOTIONAL EXPRESSION OR AFFECTIVE FLATTENING, POVERTY OF SPEECH (ALOGIA) OR AN INABILITY TO INITIATE OR PERSIST IN GOAL-DIRECTED ACTIVITIES (AVOLITION) PROMINENT DURING THE INTERVIEW?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "82_mini_section_k11_additional",
                            "title": "K11: If a mood episode or manic episode is present- Has the MDD or Manic/Hypomanic mood disturbance been present for the majority of the total duration or the active and residual periods of the psychotic symptoms?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        }
                    ]
                },
                {
                    "type": "panel",
                    "name": "K11b_panel1",
                    "visibleIf": "(sum({60_mini_section_k1a} = 'yes', {62_mini_section_k2a} = 'yes', {64_mini_section_k3a} = 'yes', {66_mini_section_k4a} = 'yes', {68_mini_section_k5a} = 'yes', {70_mini_section_k6a} = 'yes', {74_mini_section_k7a} = 'yes') > 0) and {82_mini_section_k11_additional} = 'yes'",
                    "title": "SECTION K11b - ASK THE PATIENT",
                    "elements": [
                        {
                            "type": "boolean",
                            "name": "83_mini_section_k11b",
                            "visibleIf": "(sum({60_mini_section_k1a} = 'yes', {62_mini_section_k2a} = 'yes', {64_mini_section_k3a} = 'yes', {66_mini_section_k4a} = 'yes', {68_mini_section_k5a} = 'yes', {70_mini_section_k6a} = 'yes', {74_mini_section_k7a} = 'yes') > 0) and {82_mini_section_k11_additional} = 'yes'",
                            "title": "K11 b: You told me earlier that you had periods when you felt depressed/high/persistently irritable. Were the beliefs and experiences you just described restricted exclusively to times when you were feeling depressed/high/irritable?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "84_mini_section_k11b2",
                            "visibleIf": "(sum({60_mini_section_k1a} = 'yes', {62_mini_section_k2a} = 'yes', {64_mini_section_k3a} = 'yes', {66_mini_section_k4a} = 'yes', {68_mini_section_k5a} = 'yes', {70_mini_section_k6a} = 'yes', {74_mini_section_k7a} = 'yes') > 0) and {82_mini_section_k11_additional} = 'yes' and {83_mini_section_k11b} = 'yes'",
                            "title": "K11 b2: And did this (above answer) last for 2 weeks or more?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        }
                    ]
                }
            ],
            "title": "CLINICIAN'S JUDGMENT"
        },
        {
            "name": "page8",
            "elements": [
                {
                    "type": "panel",
                    "name": "mini_section_o",
                    "elements": [
                        {
                            "type": "radiogroup",
                            "name": "85_mini_section_o1a",
                            "title": "O1a: Just before these current symptoms began: were you taking any drugs or medicines, or in withdrawal from any of these?",
                            "choices": [
                                {
                                    "value": "no",
                                    "text": "No"
                                },
                                {
                                    "value": "yes",
                                    "text": "Yes"
                                },
                                {
                                    "value": "uncertain",
                                    "text": "Uncertain"
                                }
                            ]
                        },
                        {
                            "type": "radiogroup",
                            "name": "86_mini_section_o1b",
                            "title": "O1b: Just before these current symptoms began: Did you have any medical illness?",
                            "choices": [
                                {
                                    "value": "no",
                                    "text": "No"
                                },
                                {
                                    "value": "yes",
                                    "text": "Yes"
                                },
                                {
                                    "value": "uncertain",
                                    "text": "Uncertain"
                                }
                            ]
                        },
                        {
                            "type": "radiogroup",
                            "name": "87_mini_section_o2",
                            "enableIf": "{mini_section_o2_visibility} = 'yes'",
                            "title": "O2: If O1a or O1b is coded YES, In the clinician's judgment, is either likely to be a direct cause of the patient's disorder? If necessary, ask additional open-ended questions.",
                            "choices": [
                                {
                                    "value": "no",
                                    "text": "No"
                                },
                                {
                                    "value": "yes",
                                    "text": "Yes"
                                },
                                {
                                    "value": "uncertain",
                                    "text": "Uncertain"
                                }
                            ]
                        }
                    ],
                    "title": "SECTION O: RULE OUT MEDICAL, ORGANIC OR DRUG CAUSES FOR ALL DISORDERS"
                },
                {
                    "type": "panel",
                    "name": "C8_panel",
                    "visibleIf": "{mini_c8_panel_visibility} = 'yes'",
                    "title": "Section C8 a,b,c",
                    "elements": [
                        {
                            "type": "boolean",
                            "name": "88_mini_section_c8a",
                            "visibleIf": "{mini_c8_panel_visibility} = 'yes' and ({auto7_mini_section_c_manic_episode_current} = 'yes' or {auto8_mini_section_c_manic_episode_past} = 'yes')",
                            "title": "C8 a: Did you have two or more of these (manic) episodes lasting 7 days or more (C4c) in your lifetime (including the current episode if present)?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "89_mini_section_c8b",
                            "visibleIf": "{mini_c8_panel_visibility} = 'yes' and ({auto7_mini_section_c_manic_episode_current} = 'yes' or {auto8_mini_section_c_manic_episode_past} = 'yes' or {auto10_mini_section_c_hypomanic_episode_current} = 'yes' or {auto11_mini_section_c_hypomanic_episode_past} = 'yes')",
                            "title": "C8 b: Did you have two or more of these (hypomanic) episodes lasting 4 days or more (C4b) in your lifetime (including the current episode)?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "90_mini_section_c8c",
                            "visibleIf": "{mini_c8_panel_visibility} = 'yes' and {auto11_mini_section_c_hypomanic_episode_past} = 'yes'",
                            "title": "C8 c: Did you have these hypomanic symptoms lasting only 1 to 3 days (C4a) 2 or more times in your lifetime (including the current episode if present)?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        }
                    ]
                },
                {
                    "type": "panel",
                    "name": "N1_to_N7_panel1",
                    "title": "SECTION N - GENERALIZED ANXIETY DISORDER",
                    "elements": [
                        {
                            "type": "boolean",
                            "name": "91_mini_section_n1a",
                            "title": "N1 a: Were you excessively anxious or worried about several routine things, over the past 6 months?",
                            "description": "IN ENGLISH, IF THE PATIENT IS UNCLEAR ABOUT WHAT YOU MEAN, PROBE BY ASKING (Do other think that you are a worrier or a 'worry wart'?) AND GET EXAMPLES.",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "92_mini_section_n1b",
                            "visibleIf": "{91_mini_section_n1a} = 'yes'",
                            "title": "N1 b: Are these anxieties and worries present most days?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "93_mini_section_n2",
                            "visibleIf": "{91_mini_section_n1a} = 'yes' or {92_mini_section_n1b} = 'yes'",
                            "title": "N2: Do you find it difficult to control the worries?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "94_mini_section_n3a",
                            "visibleIf": "({91_mini_section_n1a} = 'yes' or {92_mini_section_n1b} = 'yes') and {93_mini_section_n2} = 'yes'",
                            "title": "N3 a: When you were anxious over the past 6 months, did you, most of the time: Feel restless, keyed up, or on edge?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "95_mini_section_n3b",
                            "visibleIf": "({91_mini_section_n1a} = 'yes' or {92_mini_section_n1b} = 'yes') and {93_mini_section_n2} = 'yes'",
                            "title": "N3 b: When you were anxious over the past 6 months, did you, most of the time: Have muscle tension?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "96_mini_section_n3c",
                            "visibleIf": "({91_mini_section_n1a} = 'yes' or {92_mini_section_n1b} = 'yes') and {93_mini_section_n2} = 'yes'",
                            "title": "N3 c: When you were anxious over the past 6 months, did you, most of the time: Feel tired, weak, or exhausted easily?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "97_mini_section_n3d",
                            "visibleIf": "({91_mini_section_n1a} = 'yes' or {92_mini_section_n1b} = 'yes') and {93_mini_section_n2} = 'yes'",
                            "title": "N3 d: When you were anxious over the past 6 months, did you, most of the time: Have difficulty concentrating or find your mind going blank?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "98_mini_section_n3e",
                            "visibleIf": "({91_mini_section_n1a} = 'yes' or {92_mini_section_n1b} = 'yes') and {93_mini_section_n2} = 'yes'",
                            "title": "N3 e: When you were anxious over the past 6 months, did you, most of the time: Feel irritable?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "99_mini_section_n3f",
                            "visibleIf": "({91_mini_section_n1a} = 'yes' or {92_mini_section_n1b} = 'yes') and {93_mini_section_n2} = 'yes'",
                            "title": "N3 f: When you were anxious over the past 6 months, did you, most of the time: Have difficulty sleeping (difficulty falling asleep, waking up in the middle of the night, early morning wakening, or sleeping excessively)?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "100_mini_section_n4",
                            "visibleIf": "({91_mini_section_n1a} = 'yes' or {92_mini_section_n1b} = 'yes') and {93_mini_section_n2} = 'yes' and (sum({94_mini_section_n3a} = 'yes',{95_mini_section_n3b} = 'yes',{96_mini_section_n3c} = 'yes',{97_mini_section_n3d} = 'yes',{98_mini_section_n3e} = 'yes',{99_mini_section_n3f} = 'yes') > 2)",
                            "title": "N4: Do these anxieties and worries significantly disrupt your ability to work, to function socially, or in your relationships, or in other important areas of your life, or cause you significant distress?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "101_mini_section_n5a",
                            "visibleIf": "({91_mini_section_n1a} = 'yes' or {92_mini_section_n1b} = 'yes') and {93_mini_section_n2} = 'yes' and (sum({94_mini_section_n3a} = 'yes',{95_mini_section_n3b} = 'yes',{96_mini_section_n3c} = 'yes',{97_mini_section_n3d} = 'yes',{98_mini_section_n3e} = 'yes',{99_mini_section_n3f} = 'yes') > 2) and {100_mini_section_n4} = 'yes'",
                            "title": "N5 a: ARE THE PATIENT ANXIETY SYMPTOMS IN N3, OR THE WORRIES, RESTRICTED EXCLUSIVELY TO --> DEPRESSIVE DISORDER?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "102_mini_section_n5b",
                            "visibleIf": "({91_mini_section_n1a} = 'yes' or {92_mini_section_n1b} = 'yes') and {93_mini_section_n2} = 'yes' and (sum({94_mini_section_n3a} = 'yes',{95_mini_section_n3b} = 'yes',{96_mini_section_n3c} = 'yes',{97_mini_section_n3d} = 'yes',{98_mini_section_n3e} = 'yes',{99_mini_section_n3f} = 'yes') > 2) and {100_mini_section_n4} = 'yes'",
                            "title": "N5 b: ARE THE PATIENT ANXIETY SYMPTOMS IN N3, OR THE WORRIES, RESTRICTED EXCLUSIVELY TO --> A BIPOLAR DISORDER?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "103_mini_section_n5c",
                            "visibleIf": "({91_mini_section_n1a} = 'yes' or {92_mini_section_n1b} = 'yes') and {93_mini_section_n2} = 'yes' and (sum({94_mini_section_n3a} = 'yes',{95_mini_section_n3b} = 'yes',{96_mini_section_n3c} = 'yes',{97_mini_section_n3d} = 'yes',{98_mini_section_n3e} = 'yes',{99_mini_section_n3f} = 'yes') > 2) and {100_mini_section_n4} = 'yes'",
                            "title": "N5 c: ARE THE PATIENT ANXIETY SYMPTOMS IN N3, OR THE WORRIES, RESTRICTED EXCLUSIVELY TO --> A PSYCHOTIC DISORDER?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "104_mini_section_n6",
                            "visibleIf": "({auto19_mini_section_n5} = 'yes')",
                            "title": "N6: IS THE ANXIETY OR WORRY ITSELF SUFFICIENTLY SEVERE TO REQUIRE SPECIAL ATTENTION BEYOND THE STANDARD TREATMENT FOR THE DEPRESSIVE, BIPOLAR, OR PSYCHOTIC DISORDER?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        },
                        {
                            "type": "boolean",
                            "name": "105_mini_section_n7",
                            "visibleIf": "({91_mini_section_n1a} = 'yes' or {92_mini_section_n1b} = 'yes') and {93_mini_section_n2} = 'yes' and (sum({94_mini_section_n3a} = 'yes',{95_mini_section_n3b} = 'yes',{96_mini_section_n3c} = 'yes',{97_mini_section_n3d} = 'yes',{98_mini_section_n3e} = 'yes',{99_mini_section_n3f} = 'yes') > 2) and {100_mini_section_n4} = 'yes'",
                            "title": "N7: ARE THE PATIENT ANXIETY SYMPTOMS IN N3, OR THE WORRIES, RESTRICTED EXCLUSIVELY TO, OR BETTER EXPLAINED BY ANY PSYCHIATRIC DISORDER?",
                            "hideNumber": true,
                            "valueTrue": "yes",
                            "valueFalse": "no"
                        }
                    ]
                }
            ],
            "title": "SECTION O, SECTION C AND SECTION N"
        }
    ],
    "showQuestionNumbers": "onPage",
    "calculatedValues": [
        {
            name: "auto1_mini_section_a_mde",
            expression: "iif({auto2_mini_section_a_mde_current} = 'yes' or {auto3_mini_section_a_mde_past} = 'yes', 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto2_mini_section_a_mde_current",
            expression: "iif((sum({1_mini_section_a1a} = 'yes', {2_mini_section_a1b} = 'yes', {3_mini_section_a2a} = 'yes', {4_mini_section_a2b} = 'yes', {5_mini_section_a3a_past_two_weeks} = 'yes', {7_mini_section_a3b_past_two_weeks} = 'yes', {9_mini_section_a3c_past_two_weeks} = 'yes', {11_mini_section_a3d_past_two_weeks} = 'yes', {13_mini_section_a3e_past_two_weeks} = 'yes', {17_mini_section_a3f_past_two_weeks} = 'yes', {19_mini_section_a3g_past_two_weeks} = 'yes') > 4 and {21_mini_section_a4_past_two_weeks} = 'yes' and {auto33_mini_section_o2_summary} = 'yes'), 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto3_mini_section_a_mde_past",
            expression: "iif((sum({1_mini_section_a1a} = 'yes', {2_mini_section_a1b} = 'yes', {3_mini_section_a2a} = 'yes', {4_mini_section_a2b} = 'yes', {6_mini_section_a3a_past_episode} = 'yes', {8_mini_section_a3b_past_episode} = 'yes', {10_mini_section_a3c_past_episode} = 'yes', {12_mini_section_a3d_past_episode} = 'yes', {14_mini_section_a3e_past_episode} = 'yes', {18_mini_section_a3f_past_episode} = 'yes', {20_mini_section_a3g_past_episode} = 'yes') > 4 and {22_mini_section_a4_past_episode} = 'yes' and {auto33_mini_section_o2_summary} = 'yes'), 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto4_mini_section_a_mde_recurrent",
            expression: "iif({auto1_mini_section_a_mde} = 'yes' and {23_mini_section_a5_past_episode} = 'yes', 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto5_mini_section_c3_summary_current_episode",
            expression: "iif((sum({31_mini_section_c3a_current_episode} = 'yes', {35_mini_section_c3b_current_episode} = 'yes', {37_mini_section_c3c_current_episode} = 'yes', {39_mini_section_c3d_current_episode} = 'yes', {41_mini_section_c3e_current_episode} = 'yes', {43_mini_section_c3f_current_episode} = 'yes', {45_mini_section_c3g_current_episode} = 'yes') > 3 and {43_mini_section_c3f_current_episode} = 'yes' and {28_mini_section_c1b} != 'yes') or (sum({31_mini_section_c3a_current_episode} = 'yes', {35_mini_section_c3b_current_episode} = 'yes', {37_mini_section_c3c_current_episode} = 'yes', {39_mini_section_c3d_current_episode} = 'yes', {41_mini_section_c3e_current_episode} = 'yes', {43_mini_section_c3f_current_episode} = 'yes', {45_mini_section_c3g_current_episode} = 'yes') > 2 and {43_mini_section_c3f_current_episode} = 'yes' and {28_mini_section_c1b} = 'yes'), 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto6_mini_section_c3_summary_past_episode",
            expression: "iif((sum({33_mini_section_c3a_past_episode} = 'yes', {36_mini_section_c3b_past_episode} = 'yes', {38_mini_section_c3c_past_episode} = 'yes', {40_mini_section_c3d_past_episode} = 'yes', {42_mini_section_c3e_past_episode} = 'yes', {44_mini_section_c3f_past_episode} = 'yes', {46_mini_section_c3g_past_episode} = 'yes') > 3 and {44_mini_section_c3f_past_episode} = 'yes' and {27_mini_section_c1a} != 'yes') or (sum({33_mini_section_c3a_past_episode} = 'yes', {36_mini_section_c3b_past_episode} = 'yes', {38_mini_section_c3c_past_episode} = 'yes', {40_mini_section_c3d_past_episode} = 'yes', {42_mini_section_c3e_past_episode} = 'yes', {44_mini_section_c3f_past_episode} = 'yes', {46_mini_section_c3g_past_episode} = 'yes') > 2 and {44_mini_section_c3f_past_episode} = 'yes' and {27_mini_section_c1a} = 'yes'), 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto7_mini_section_c_manic_episode_current",
            expression: "iif({auto5_mini_section_c3_summary_current_episode} = 'yes' and {57_mini_section_c7_current_episode} = 'yes' and sum({51_mini_section_c4c_current_episode} = 'yes', {53_mini_section_c5_current_episode} = 'yes', {55_mini_section_c6_current_episode} = 'yes', {60_mini_section_k1a} = 'yes', {62_mini_section_k2a} = 'yes', {64_mini_section_k3a} = 'yes', {66_mini_section_k4a} = 'yes', {68_mini_section_k5a} = 'yes', {70_mini_section_k6a} = 'yes', {74_mini_section_k7a} = 'yes', {76_mini_section_k8a} = 'yes') > 0 and {auto33_mini_section_o2_summary} = 'yes', 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto8_mini_section_c_manic_episode_past",
            expression: "iif({auto6_mini_section_c3_summary_past_episode} = 'yes' and {58_mini_section_c7_past_episode} = 'yes' and ({52_mini_section_c4c_past_episode} = 'yes' or {54_mini_section_c5_past_episode} = 'yes' or {56_mini_section_c6_past_episode} = 'yes' or {60_mini_section_k1a} = 'yes' or {62_mini_section_k2a} = 'yes' or {64_mini_section_k3a} = 'yes' or {66_mini_section_k4a} = 'yes' or {68_mini_section_k5a} = 'yes' or {70_mini_section_k6a} = 'yes' or {74_mini_section_k7a} = 'yes' or {76_mini_section_k8a} = 'yes') and {auto33_mini_section_o2_summary} = 'yes', 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto9_mini_section_c_manic_episode",
            expression: "iif(({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and({auto7_mini_section_c_manic_episode_current} = 'yes' or {auto8_mini_section_c_manic_episode_past} = 'yes'), 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto10_mini_section_c_hypomanic_episode_current",
            expression: "iif(({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and {auto5_mini_section_c3_summary_current_episode} = 'yes' and {53_mini_section_c5_current_episode} = 'no' and {55_mini_section_c6_current_episode} = 'no' and {57_mini_section_c7_current_episode} = 'yes' and ({49_mini_section_c4b_current_episode} = 'yes' or {51_mini_section_c4c_current_episode} = 'yes') and {auto33_mini_section_o2_summary} = 'yes' and {60_mini_section_k1a} = 'no' and {62_mini_section_k2a} = 'no' and {64_mini_section_k3a} = 'no' and {66_mini_section_k4a} = 'no' and {68_mini_section_k5a} = 'no' and {70_mini_section_k6a} = 'no' and {74_mini_section_k7a} = 'no' and {76_mini_section_k8a} = 'no' and {auto7_mini_section_c_manic_episode_current} = 'no', 'yes', 'no')",
            includeIntoResult: true

        },
        {
            name: "auto11_mini_section_c_hypomanic_episode_past",
            expression: "iif(({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and {auto8_mini_section_c_manic_episode_past} = 'yes', 'not_explored', iif(({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and {auto6_mini_section_c3_summary_past_episode} = 'yes' and {58_mini_section_c7_past_episode} = 'yes' and ({52_mini_section_c4c_past_episode} = 'yes' or {54_mini_section_c5_past_episode} = 'yes' or {56_mini_section_c6_past_episode} = 'yes' or {60_mini_section_k1a} = 'yes' or {62_mini_section_k2a} = 'yes' or {64_mini_section_k3a} = 'yes' or {66_mini_section_k4a} = 'yes' or {68_mini_section_k5a} = 'yes' or {70_mini_section_k6a} = 'yes' or {74_mini_section_k7a} = 'yes' or {76_mini_section_k8a} = 'yes') and {auto33_mini_section_o2_summary} = 'yes', 'yes', 'no'))",
            includeIntoResult: true
        },
        {
            name: "auto12_mini_section_c_hypomanic_symptoms_current",
            expression: "iif(({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and {auto5_mini_section_c3_summary_current_episode} = 'yes' and {47_mini_section_c4a_current_episode} = 'yes' and {53_mini_section_c5_current_episode} = 'no' and {auto7_mini_section_c_manic_episode_current} = 'no' and {auto10_mini_section_c_hypomanic_episode_current} = 'no' , 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto13_mini_section_c_hypomanic_symptoms_past",
            expression: "iif((({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and {auto8_mini_section_c_manic_episode_past} = 'yes' or {auto11_mini_section_c_hypomanic_episode_past} = 'yes'), 'not_explored', iif(({27_mini_section_c1a} = 'yes' or {29_mini_section_c2a} = 'yes') and {auto6_mini_section_c3_summary_past_episode} = 'yes' and {48_mini_section_c4a_past_episode} = 'yes' and {54_mini_section_c5_past_episode} = 'no' , 'yes', 'no'))",
            includeIntoResult: true
        },
        {
            name: "auto14_mini_section_k11a",
            expression: "iif((sum({60_mini_section_k1a} = 'yes', {62_mini_section_k2a} = 'yes', {64_mini_section_k3a} = 'yes', {66_mini_section_k4a} = 'yes', {68_mini_section_k5a} = 'yes', {70_mini_section_k6a} = 'yes', {74_mini_section_k7a} = 'yes') > 0 and ({auto2_mini_section_a_mde_current} = 'yes' or {auto3_mini_section_a_mde_past} = 'yes' or {auto4_mini_section_a_mde_recurrent} = 'yes' or {auto7_mini_section_c_manic_episode_current} = 'yes', {auto8_mini_section_c_manic_episode_past} = 'yes' or {auto10_mini_section_c_hypomanic_episode_current} = 'yes', {auto11_mini_section_c_hypomanic_episode_past} = 'yes') and {82_mini_section_k11_additional} = 'yes', 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto15_mini_section_k11b_mood_disorder_with_psycothic_features_lifetime",
            expression: "iif({84_mini_section_k11b2} = 'yes', 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto16_mini_section_k12_mood_disorder_with_psycothic_features_current",
            expression: "iif((sum({61_mini_section_k1b} = 'yes', {63_mini_section_k2b} = 'yes', {65_mini_section_k3b} = 'yes', {67_mini_section_k4b} = 'yes', {69_mini_section_k5b} = 'yes', {72_mini_section_k6b} = 'yes', {75_mini_section_k7b} = 'yes') > 0 and ({auto2_mini_section_a_mde_current} = 'yes' or {auto7_mini_section_c_manic_episode_current} = 'yes' or {auto10_mini_section_c_hypomanic_episode_current} = 'yes'), 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto17_mini_section_k13_psycothic_disorder_current",
            expression: "iif((sum({61_mini_section_k1b} = 'yes', {63_mini_section_k2b} = 'yes', {65_mini_section_k3b} = 'yes', {67_mini_section_k4b} = 'yes', {69_mini_section_k5b} = 'yes', {72_mini_section_k6b} = 'yes', {75_mini_section_k7b} = 'yes') > 0 and sum({61_mini_section_k1b} = 'yes', {63_mini_section_k2b} = 'yes', {65_mini_section_k3b} = 'yes', {67_mini_section_k4b} = 'yes', {69_mini_section_k5b} = 'yes', {72_mini_section_k6b} = 'yes', {75_mini_section_k7b} = 'yes', {77_mini_section_k8b} = 'yes', {79_mini_section_k9b} = 'yes', {81_mini_section_k10b} = 'yes') > 1 and {auto33_mini_section_o2_summary} = 'yes'), 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto18_mini_section_k14_psycothic_disorder_lifetime",
            expression: "iif(({auto17_mini_section_k13_psycothic_disorder_current} = 'yes' or (sum({60_mini_section_k1a} = 'yes', {62_mini_section_k2a} = 'yes', {64_mini_section_k3a} = 'yes', {66_mini_section_k4a} = 'yes', {68_mini_section_k5a} = 'yes', {70_mini_section_k6a} = 'yes', {74_mini_section_k7a} = 'yes') > 0) and sum({60_mini_section_k1a} = 'yes', {62_mini_section_k2a} = 'yes', {64_mini_section_k3a} = 'yes', {66_mini_section_k4a} = 'yes', {68_mini_section_k5a} = 'yes', {70_mini_section_k6a} = 'yes', {74_mini_section_k7a} = 'yes', {76_mini_section_k8a} = 'yes', {78_mini_section_k9a} = 'yes', {80_mini_section_k10a} = 'yes') > 1 and {auto33_mini_section_o2_summary} = 'yes', 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto19_mini_section_n5",
            expression: "iif(({101_mini_section_n5a} = 'yes' or {102_mini_section_n5b} = 'yes' or {103_mini_section_n5c} = 'yes'), 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto20_mini_section_n_generalized_anxiety_disorder_current",
            expression: "iif({105_mini_section_n7} = 'no' and {auto33_mini_section_o2_summary} = 'yes', 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "mini_section_a1b_visibility",
            expression: "iif({1_mini_section_a1a} = 'no', 'no', 'yes')"
        },
        {
            name: "mini_section_a2b_visibility",
            expression: "iif({3_mini_section_a2a} = 'no', 'no', 'yes')"
        },
        {
            name: "mini_section_c1b_visibility",
            expression: "iif({27_mini_section_c1a} = 'no', 'no', 'yes')"
        },
        {
            name: "mini_section_c2b_visibility",
            expression: "iif({29_mini_section_c2a} = 'no', 'no', 'yes')"
        },
        {
            name: "mini_section_o2_visibility",
            expression: "iif(({85_mini_section_o1a} = 'no' and {86_mini_section_o1b} = 'no'), 'no', 'yes')"
        },
        {
            name: "mini_c8_panel_visibility",
            expression: "iif({auto7_mini_section_c_manic_episode_current} = 'yes' or {auto8_mini_section_c_manic_episode_past} = 'yes') or ({auto7_mini_section_c_manic_episode_current} = 'yes' or {auto8_mini_section_c_manic_episode_past} = 'yes' or {auto10_mini_section_c_hypomanic_episode_current} = 'yes' or {auto11_mini_section_c_hypomanic_episode_past} = 'yes') or ({auto11_mini_section_c_hypomanic_episode_past} = 'yes'), 'yes', 'no')"
        },
        {
            name: "auto21_mini_mood_disorders_1a",
            expression: "iif({auto15_mini_section_k11b_mood_disorder_with_psycothic_features_lifetime} = 'yes', 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto22_mini_mood_disorders_1b",
            expression: "iif({auto16_mini_section_k12_mood_disorder_with_psycothic_features_current} = 'yes', 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto23_mini_mood_disorders_2a_current",
            expression: "iif({13_mini_section_a3e_past_two_weeks} = 'yes' or sum({61_mini_section_k1b} = 'yes', {63_mini_section_k2b} = 'yes', {65_mini_section_k3b} = 'yes', {67_mini_section_k4b} = 'yes', {69_mini_section_k5b} = 'yes', {72_mini_section_k6b} = 'yes', {75_mini_section_k7b} = 'yes') > 0, 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto24_mini_mood_disorders_2a_past",
            expression: "iif({14_mini_section_a3e_past_episode} = 'yes' or sum({60_mini_section_k1a} = 'yes', {62_mini_section_k2a} = 'yes', {64_mini_section_k3a} = 'yes', {66_mini_section_k4a} = 'yes', {68_mini_section_k5a} = 'yes', {70_mini_section_k6a} = 'yes', {74_mini_section_k7a} = 'yes') > 0, 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto25_mini_mood_disorders_2b_current",
            expression: "iif({31_mini_section_c3a_current_episode} = 'yes' or sum({61_mini_section_k1b} = 'yes', {63_mini_section_k2b} = 'yes', {65_mini_section_k3b} = 'yes', {67_mini_section_k4b} = 'yes', {69_mini_section_k5b} = 'yes', {72_mini_section_k6b} = 'yes', {75_mini_section_k7b} = 'yes') > 0, 'yes', 'no')",
            includeIntoResult: true
        }, {
            name: "auto26_mini_mood_disorders_2b_past",
            expression: "iif({33_mini_section_c3a_past_episode} = 'yes' or sum({60_mini_section_k1a} = 'yes', {62_mini_section_k2a} = 'yes', {64_mini_section_k3a} = 'yes', {66_mini_section_k4a} = 'yes', {68_mini_section_k5a} = 'yes', {70_mini_section_k6a} = 'yes', {74_mini_section_k7a} = 'yes') > 0, 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto27_mini_mood_disorders_2c_mdd",
            expression: "iif(sum({auto2_mini_section_a_mde_current} = 'yes', {auto3_mini_section_a_mde_past} = 'yes', {auto4_mini_section_a_mde_recurrent} = 'yes') > 0 and {auto7_mini_section_c_manic_episode_current} = 'no' and {auto8_mini_section_c_manic_episode_past} = 'no' and {auto10_mini_section_c_hypomanic_episode_current} = 'no' and {auto11_mini_section_c_hypomanic_episode_past} = 'no' and {auto33_mini_section_o2_summary} = 'yes', 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto28_mini_mood_disorders_2c_mdd_current",
            expression: "iif({auto2_mini_section_a_mde_current} = 'yes' and {auto7_mini_section_c_manic_episode_current} = 'no' and {auto10_mini_section_c_hypomanic_episode_current} = 'no' and {auto33_mini_section_o2_summary} = 'yes', 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto29_mini_mood_disorders_2c_mdd_past",
            expression: "iif({auto3_mini_section_a_mde_past} = 'yes' and {auto8_mini_section_c_manic_episode_past} = 'no' and {auto11_mini_section_c_hypomanic_episode_past} = 'no' and {auto33_mini_section_o2_summary} = 'yes', 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto30_mini_mood_disorders_2c_mdd_recurrent",
            expression: "iif({auto27_mini_mood_disorders_2c_mdd} = 'yes' and (sum({auto2_mini_section_a_mde_current} = 'yes', {auto3_mini_section_a_mde_past} = 'yes', {23_mini_section_a5_past_episode} = 'yes') > 2 or sum({auto3_mini_section_a_mde_past} = 'yes', {23_mini_section_a5_past_episode} = 'yes') > 1), 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto31_mini_mood_disorders_2c_mdd_with_psychotic_features_current",
            expression: "iif({auto22_mini_mood_disorders_1b} = 'yes' or {auto23_mini_mood_disorders_2a_current} = 'yes', 'yes', 'no')",
            includeIntoResult: true
        },
        {
            name: "auto32_mini_mood_disorders_2c_mdd_with_psychotic_features_past",
            expression: "iif({auto24_mini_mood_disorders_2a_past} = 'yes', 'yes', 'no')",
            includeIntoResult: true
        },
    ],
    triggers: [
        { type: "setvalue", expression: "{1_mini_section_a1a} = 'no'", setToName: "2_mini_section_a1b", setValue: "no" },
        { type: "setvalue", expression: "{3_mini_section_a2a} = 'no'", setToName: "4_mini_section_a2b", setValue: "no" },
        { type: "setvalue", expression: "{27_mini_section_c1a} = 'no'", setToName: "28_mini_section_c1b", setValue: "no" },
        { type: "setvalue", expression: "{29_mini_section_c2a} = 'no'", setToName: "30_mini_section_c2b", setValue: "no" },
        { type: "setvalue", expression: "{85_mini_section_o1a} = 'no' and {86_mini_section_o1b} = 'no'", setToName: "87_mini_section_o2", setValue: "no" },
        { type: "setvalue", expression: "{87_mini_section_o2} = 'yes'", setToName: "auto33_mini_section_o2_summary", setValue: "no" },
        { type: "setvalue", expression: "{87_mini_section_o2} = 'no'", setToName: "auto33_mini_section_o2_summary", setValue: "yes" },
        { type: "setvalue", expression: "{87_mini_section_o2} = 'uncertain'", setToName: "auto33_mini_section_o2_summary", setValue: "uncertain" },
    ]
}

export const enum ActionCategory {
    status = 'STATUS',
    answer = 'ANSWER',
}
export interface SurveyAction {
    id: string;
    timestamp: firebase.firestore.Timestamp;
    category: ActionCategory;
    answer?: string | Record<string,any>;
}