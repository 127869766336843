import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_PUBLIC_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize firebase app based on the environment
export function initFirebase() {
    if (!firebase.apps.length) {
        firebase.initializeApp(config);
    }
}

export async function signInWithCustomToken(token: string) {
    try {
        return await firebase.auth().signInWithCustomToken(token).then(async () => {
            const currentUser = firebase.auth().currentUser;
            return currentUser;
        });
    } catch (error) {
        console.log('Error while sign-in in the custom auth token: ', error);
        return null;
    }
}

// Initialize firebase app based on the environment
export function logoutFirebase() {
    if (firebase.apps.length) {
        firebase.auth().signOut();
    }
}

export default firebase;
