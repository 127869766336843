import { GA_EVENT_TYPES, GA_PREFIX, GA_SCREEN_NAMES } from '../constants';

declare global {
  interface Window {
    dataLayer: Array<Array<string> | IArguments>;
    gtag: (...args: any) => void;
    ga: (...args: any) => void;
  }

  interface Document {
    myCookie: string;
  }
}

interface CommonInfo {
  appName?: string;
  appVersion?: string;
  partnerId?: string;
  ext_ref_id?: string;
  interactionId?: string;
}

let isProxyScriptInitialized = false;
let isProxyScriptReady = false;
const commonInfo: CommonInfo = {};

type EventName = string;
type EventParams = Record<string, string | number>;

const pendingEvents: Array<[EventName, EventParams]> = [];

const getEnvName = () => {
  switch (process.env.REACT_APP_FIREBASE_PROJECT_ID) {
    case 'rising-higher-dev':
      return 'dev';
    case 'rising-higher-qa':
      return 'qa';
    case 'rising-higher-demo':
      return 'demo';
    default:
      return 'prod';
  }
};

export function attachProxyScript({
  appName,
  appVersion,
  interactionId,
  partnerId,
  ext_ref_id,
}: CommonInfo) {
  if (isProxyScriptInitialized) {
    return;
  }

  commonInfo.partnerId = partnerId;
  commonInfo.ext_ref_id = ext_ref_id;

  isProxyScriptInitialized = true;

  const script = document.createElement('script');
  script.src = `/ga-proxy.${getEnvName()}.js`;
  script.async = true;
  script.onload = () => {
    window.dataLayer = window.dataLayer || [];

    // define gtag
    //  NOTE: rest params(`...args`) does not work. only `arguments` makes analytics work.
    // eslint-disable-next-line prefer-rest-params
    window.gtag = function gtag() { window.dataLayer.push(arguments); };

    window.gtag('js', new Date());

    if (interactionId) {
      window.gtag('config', process.env.REACT_APP_FIREBASE_MEASUREMENT_ID, {
        // eslint-disable-next-line camelcase
        user_id: interactionId,
      });
    } else {
      window.gtag('config', process.env.REACT_APP_FIREBASE_MEASUREMENT_ID);
    }

    isProxyScriptReady = true;

    // send any previous pending events
    pendingEvents.forEach((eventArgs) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      logEvent(eventArgs[0], eventArgs[1]);
    });
  };

  document.myCookie = '';
  document.body.appendChild(script);
}

export function logEvent(name: EventName, obj?: EventParams) {
  const eventName = `${GA_PREFIX}${name}`;

  if (global.window && global.window.navigator) {
    obj = obj || {};

    if (commonInfo.partnerId) {
      // eslint-disable-next-line no-param-reassign
      obj.partner_id = commonInfo.partnerId;
    }

    if (commonInfo.ext_ref_id) {
      // eslint-disable-next-line no-param-reassign
      obj.ehRefId = commonInfo.ext_ref_id;
    }

    if (!isProxyScriptReady) {
      pendingEvents.push([name, obj]);
      return;
    }

    if (window.gtag) {
      window.gtag('event', eventName, obj);
    } else if (window.ga) {
      window.ga(eventName, obj);
    } else {
      console.log('gtag/ga is not loaded');
    }
  }
}

/**
 * Logs the analytics event about the screen view
 *
 * @param {GA_SCREEN_NAMES} screenName name of the screen
 * @param {string | undefined} action action over the screen
 * @param {Record<string, string | number> | undefined} eventParams params in input
 */
export function logScreenView(screenName: GA_SCREEN_NAMES, action?: string, eventParams?: Record<string, string | number | boolean>) {
  logEvent(GA_EVENT_TYPES.SCREEN_VIEW, {
    event_category: screenName,
    ...(action && {
      event_action: action,
    }),
    ...eventParams,
  });

  return {
    logAsFunnelEvent: () => {
      logEvent(action ? `fscr_${screenName}_${action}` : `fscr_${screenName}`);
    },
  };
}

/**
 * Logs the analytics event about the button click
 *
 * @param {GA_SCREEN_NAMES} screenName source/screen where the event occurred
 * @param {string} label Event label - what is displayed on the label
 * @param {string} action Event action - what does that event click does
 */
export function logButtonClick(screenName: GA_SCREEN_NAMES, label: string, action: string, eventParams?: Record<string, string | number | boolean>) {
  logEvent(action, {
    event_category: screenName,
    event_action: action,
    event_label: label,
    ...eventParams
  });

  return {
    logAsFunnelEvent: () => {
      logEvent(`fbtn_${screenName}_${action}`);
    },
  };
}

/**
 * Logs the analytics event about the link click/navs
 *
 * @param {GA_SCREEN_NAMES} screenName source/screen where the event occurred
 * @param {string} label Event label - what is displayed on the label
 * @param {string} action Event action - what does that event click do
 */
export function logLinkClick(screenName: GA_SCREEN_NAMES, label: string, action: string) {
  logEvent(action, {
    event_category: screenName,
    event_action: action,
    event_label: label,
  });

  return {
    logAsFunnelEvent: () => {
      logEvent(`flnk_${screenName}_${action}`);
    },
  };
}
