import AssessmentTable from "./AssessmentTableSingleWidget";

function init(CustomWidgetCollection, Serializer) {
    const widget = {
        title: "Custom Assessment Widget",
        iconName: "icon-editor",
        widgetIsLoaded: function () {
            return true; //We do not have external scripts
        },
        isFit: function (question) {
            return question.getType() === "custom_assessment_widget";
        },
        init() {
            Serializer.addProperty('question', {
                name: 'customProperties',
                category: 'general',
            });
            Serializer.addClass("custom_assessment_widget", [], null, "empty");
        },
        render: function (question) {
            const customProperties = question["customProperties"];
            const { description, columns, rows } = customProperties;

            // Render the DepressionAssessmentTable custom widget inside the survey question element
            return <AssessmentTable
                name={question.name}
                question={question}
                description={description}
                columns={columns}
                rows={rows}
            />;
        },
    };

    CustomWidgetCollection.Instance.addCustomWidget(widget);
};

export default init;